export const hourRangeList = [
  {
    label: '00:00 - 01:00',
    value: '00:00 - 01:00',
  },
  {
    label: '01:00 - 02:00',
    value: '01:00 - 02:00',
  },
  {
    label: '02:00 - 03:00',
    value: '02:00 - 03:00',
  },
  {
    label: '03:00 - 04:00',
    value: '03:00 - 04:00',
  },
  {
    label: '04:00 - 05:00',
    value: '04:00 - 05:00',
  },
  {
    label: '05:00 - 06:00',
    value: '05:00 - 06:00',
  },
  {
    label: '06:00 - 07:00',
    value: '06:00 - 07:00',
  },
  {
    label: '07:00 - 08:00',
    value: '07:00 - 08:00',
  },
  {
    label: '08:00 - 09:00',
    value: '08:00 - 09:00',
  },
  {
    label: '09:00 - 10:00',
    value: '09:00 - 10:00',
  },
  {
    label: '10:00 - 11:00',
    value: '10:00 - 11:00',
  },
  {
    label: '11:00 - 12:00',
    value: '11:00 - 12:00',
  },
]
