import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface getStarshipITCourierExpenditureOrdersInterface {
  take?: number
  skip?: number
  principle?: any
  courier?: any
  orderStartDate?: string
  orderEndDate?: string
  consignmentStarDate?: string
  consignmentEndDate?: string
}

export const useGetStarshipITCourierExpenditureOrders = () => {
  const mutate = useMutation(
    ({
      take,
      skip,
      principle,
      courier,
      orderStartDate,
      orderEndDate,
      consignmentStarDate,
      consignmentEndDate,
    }: getStarshipITCourierExpenditureOrdersInterface) => {
      return get(
        `/StarshipITReports/CourierExpenditure?Take=${take}&Skip=${skip}&Principle=${
          principle?.value === 'All' ? '' : principle?.value
        }&SubCourier=${
          courier?.value === 'All' ? '' : courier?.value
        }&OrderDateFrom=${orderStartDate}&OrderDateTo=${orderEndDate}&ConsignmentDateFrom=${consignmentStarDate}&ConsignmentDateTo=${consignmentEndDate}`,
      )
    },
  )

  return mutate
}

export default useGetStarshipITCourierExpenditureOrders
