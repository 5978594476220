import React, { FC, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import {
  useGetInternalHourlyReceivingOrders,
  useGetPartnerTable,
  useReportExport,
} from 'framework/api/methods'
import { Couriers } from 'static-data/couriers'
import { hourRangeList } from 'static-data/hourRangeList'

import { Button, SimpleSelect } from 'components/atoms'
import { dateToReadableString, dateToStandardFormat } from 'utils'

import { DataTable } from './DataTable'

import './datePicker.scss'

export interface HourlyReceivingOrdersReportDataTableProps {
  [x: string]: any
}

export const HourlyReceivingOrdersReportDataTable: FC<
  HourlyReceivingOrdersReportDataTableProps
> = ({ notify }: HourlyReceivingOrdersReportDataTableProps) => {
  const [rows, setRows] = useState({})
  const [tableVariables, setTableVariables] = useState({
    take: 10,
    skip: 0,
  })
  const [pageNumber, setPageNumber] = useState(1)
  const [isErrored, setIsErrored] = useState({
    hourRange: false,
    meridian: false,
  })

  const [totalRowCount, setTotalRowCount] = useState(0)
  const [isFilterMenuOpen, setIsFilterModalOpen] = useState(false)
  const [filterActive, setFilterActive] = useState(false)
  const [filterVariables, setFilterVariables] = useState<any>({
    principle: { label: 'All', value: 'All' },
    courier: { label: 'All', value: 'All' },
    orderDate: new Date(),
    hourRange: '',
    meridian: '',
    status: { label: 'All', value: 'All' },
  })
  const [toDate, setToDate] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [csvOrderData, setCsvOrderData] = useState([])

  const csvLinkEl = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const [principleList, setPrincipleList] = useState([])

  /** API Call for partner list using react-query */
  const { mutate: getPartnerListMutate } = useGetPartnerTable()

  /** Process the get partner list */
  async function getPartnerList() {
    getPartnerListMutate(
      { take: 100, skip: 0, Search: '' },
      {
        onSuccess: ({ data: successData }: any) => {
          const partners = successData.partners.map((principle: any) => {
            return {
              value: principle.code,
              label: principle.name,
            }
          })
          setPrincipleList(partners)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData?.message || 'Error...', 'error')
        },
      },
    )
  }

  /** API Call for get list using react-query */
  const {
    mutate: getInternalHourlyReceivingOrdersMutate,
    isLoading: getInternalHourlyReceivingOrdersIsLoading,
  } = useGetInternalHourlyReceivingOrders()

  /** Process the get table data */
  async function getInternalHourlyReceivingOrders(
    take: number,
    skip: number,
    principle: any,
    courier: any,
    orderStartDate: string | null,
    orderEndDate: string | null,
    status: any,
  ) {
    getInternalHourlyReceivingOrdersMutate(
      {
        take,
        skip,
        principle,
        courier,
        orderStartDate,
        orderEndDate,
        status,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setRows(successData?.reports || [])
          setTotalRowCount(successData?.totalCount)
        },
        onError: ({ response: { data: errData } }: any) => {
          //
        },
      },
    )
  }

  useEffect(() => {
    getInternalHourlyReceivingOrders(
      tableVariables.take,
      tableVariables.skip,
      filterVariables.principle,
      filterVariables.courier,
      fromDate,
      toDate,
      filterVariables.status,
    )
  }, [tableVariables])

  useEffect(() => {
    getPartnerList()
  }, [])

  useEffect(() => {
    // Given strings
    if (filterVariables?.hourRange && filterVariables?.meridian) {
      const dateString: any = dateToReadableString(filterVariables?.orderDate)
      const fromTimeString: any =
        filterVariables?.hourRange?.value?.split(' - ')[0]
      const toTimeString: any =
        filterVariables?.hourRange?.value?.split(' - ')[1]
      const amPmString: any = filterVariables?.meridian?.value

      // Extracting date components
      const dateComponents = dateString.split('/')
      const day = dateComponents[0]
      const month = dateComponents[1]
      const year = dateComponents[2]

      // Extracting from time components
      const fromTimeComponents = fromTimeString.split(':')
      let fromHour = parseInt(fromTimeComponents[0])
      const fromMinute = parseInt(fromTimeComponents[1])

      // Extracting to time components
      const toTimeComponents = toTimeString.split(':')
      let toHour = parseInt(toTimeComponents[0])
      const toMinute = parseInt(toTimeComponents[1])

      // Adjusting hour based on AM/PM
      if (amPmString === 'pm') {
        fromHour += 12
      }

      if (amPmString === 'pm') {
        toHour += 12
      }

      // Creating the Date object
      const fromDate = new Date(year, month - 1, day, fromHour, fromMinute)
      const toDate = new Date(year, month - 1, day, toHour, toMinute)

      setFromDate(dateToStandardFormat(fromDate))
      setToDate(dateToStandardFormat(toDate))
    } else {
      const from_date =
        filterVariables?.orderDate.getFullYear() +
        '-' +
        ('0' + (filterVariables?.orderDate.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + filterVariables?.orderDate.getDate()).slice(-2)

      const nextDate = new Date()
      nextDate.setDate(filterVariables?.orderDate.getDate() + 1)

      const to_date =
        nextDate?.getFullYear() +
        '-' +
        ('0' + (nextDate?.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + nextDate?.getDate()).slice(-2)

      setFromDate(from_date + 'T00:00:00.000Z')
      setToDate(to_date + 'T12:00:00.000Z')
    }
  }, [
    filterVariables?.hourRange,
    filterVariables?.meridian,
    filterVariables?.orderDate,
  ])

  /** API Call export pdf/csv using react-query */
  const { mutate: reportExportMutate } = useReportExport()

  /** Process the export csv/pdf*/
  async function reportExport(exportType: string) {
    reportExportMutate(
      {
        exportType,
        reportType: 'Internal',
        module: 'HourlyReport',
        hourlyRequest: {
          principle:
            filterVariables?.principle?.value === 'All'
              ? null
              : filterVariables?.principle?.value,
          courier:
            filterVariables?.courier?.value === 'All'
              ? null
              : filterVariables?.courier?.value,
          orderFrom: fromDate ? fromDate : null,
          orderTo: toDate ? toDate : null,
          status:
            filterVariables?.status?.value === 'All'
              ? null
              : filterVariables?.status?.value,
        },
      },
      {
        onSuccess: ({ data: successData }: any) => {
          if (exportType === 'CSV') {
            /** set data to csv document */
            setCsvOrderData(successData)

            /** trigger csv element */
            csvLinkEl?.current?.link.click()
          }
          if (exportType === 'PDF') {
            const url = window.URL.createObjectURL(new Blob([successData]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `HourlyReport.pdf`) //or any other extension
            document.body.appendChild(link)
            link.click()
          }
        },
        onError: ({ response: { data: errData } }: any) => {
          //
        },
      },
    )
  }

  const isValidInput = () => {
    if (
      (filterVariables?.hourRange && filterVariables?.meridian) ||
      (filterVariables?.hourRange === '' && filterVariables?.meridian === '')
    ) {
      return true
    } else {
      setIsErrored({
        ...isErrored,
        hourRange: filterVariables?.hourRange ? false : true,
        meridian: filterVariables?.meridian ? false : true,
      })
      return false
    }
  }

  const handleApplyFilter = () => {
    if (isValidInput()) {
      filterVariables?.principle.value === 'All' &&
      filterVariables?.courier?.value === 'All' &&
      dateToReadableString(filterVariables?.orderDate) ===
        dateToReadableString(new Date()) &&
      filterVariables?.hourRange === '' &&
      filterVariables?.meridian === '' &&
      filterVariables?.status?.value === 'All'
        ? setFilterActive(false)
        : setFilterActive(true)

      getInternalHourlyReceivingOrders(
        tableVariables.take,
        tableVariables.skip,
        filterVariables.principle,
        filterVariables.courier,
        fromDate,
        toDate,
        filterVariables.status,
      )
      setIsFilterModalOpen(false)

      setPageNumber(1)

      setIsErrored({
        ...isErrored,
        hourRange: false,
        meridian: false,
      })
    }
  }

  return (
    <div className='flex w-full flex-col justify-start items-center mt-10 h-full overflow-y-scroll'>
      <div className='flex justify-between items-center w-full mb-4'>
        <div className='flex items-center relative'>
          <Button
            onClick={() => {
              setIsFilterModalOpen(!isFilterMenuOpen)
            }}
            appearance={isFilterMenuOpen ? 'default' : 'outline'}>
            <i className='ri-filter-3-line pr-3'></i> Filter
          </Button>
          {filterActive && (
            <div className='w-[6px] h-[6px] bg-R-500 rounded-full absolute top-0 right-[-2px]'></div>
          )}
        </div>

        <div className='flex items-center gap-x-3'>
          <Button
            appearance='dull'
            onClick={() => {
              reportExport('PDF')
            }}>
            Export PDF
          </Button>
          <Button
            appearance='dull'
            onClick={() => {
              reportExport('CSV')
            }}>
            Export CSV
          </Button>
        </div>
      </div>

      {/* expanding filter panel with open close animation */}
      <AnimatePresence initial={false}>
        {isFilterMenuOpen && (
          <motion.div
            className='flex flex-col bg-white w-full'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            transition={{ type: 'tween', duration: 0.3 }}
            variants={{
              open: { opacity: 1, y: 0, height: 'auto' },
              collapsed: { opacity: -1, y: -35, height: 0 },
            }}>
            {/** Input field of filtering section */}
            <div className='flex flex-col w-full mb-4 py-2'>
              <div className='flex w-full gap-x-5'>
                <div className='flex flex-col w-1/3 z-[3]'>
                  <span className='pb-2 text-N-700'>By Principle</span>

                  <SimpleSelect
                    value={filterVariables?.principle}
                    onChange={(e: any) => {
                      setFilterVariables({
                        ...filterVariables,
                        principle: e,
                      })
                    }}
                    options={[{ label: 'All', value: 'All' }, ...principleList]}
                  />
                </div>

                <div className='flex flex-col w-1/3 z-[3]'>
                  <span className='pb-2 text-N-700'>By Courier</span>

                  <SimpleSelect
                    value={filterVariables?.courier}
                    onChange={(e: any) => {
                      setFilterVariables({
                        ...filterVariables,
                        courier: e,
                      })
                    }}
                    options={[
                      { label: 'All', value: 'All' },
                      ...Couriers,
                      { value: 'IcsWMS', label: 'IcsWMS' },
                    ]}
                  />
                </div>

                <div className='flex flex-col w-1/3 z-[3]'>
                  <span className='pb-2 text-N-700'>By Dispatch Status</span>

                  <SimpleSelect
                    value={filterVariables?.status}
                    onChange={(e: any) => {
                      setFilterVariables({
                        ...filterVariables,
                        status: e,
                      })
                    }}
                    options={[
                      { label: 'All', value: 'All' },
                      {
                        label: 'Dispatched on time',
                        value: 'Dispatched on time',
                      },
                      { label: 'Delayed', value: 'Delayed' },
                    ]}
                  />
                </div>
              </div>
              <div className='flex gap-x-4 mt-6 justify-start items-end'>
                <div className='flex flex-col w-1/2 z-[2]'>
                  <span className='pb-2 text-N-700'>Order Date and Time</span>

                  <div className='flex gap-x-3 order-date justify-end items-end'>
                    <div className='date-picker w-6/12'>
                      <DatePickerComponent
                        enabled={true}
                        onChange={(e: any) => {
                          setFilterVariables({
                            ...filterVariables,
                            orderDate: e.target.value,
                          })
                        }}
                        value={filterVariables?.orderDate}
                        allowEdit={false}
                      />
                    </div>

                    <div className='w-5/12'>
                      <SimpleSelect
                        value={filterVariables?.hourRange}
                        placeholder='hh:mm - hh:mm'
                        onChange={(e: any) => {
                          setFilterVariables({
                            ...filterVariables,
                            hourRange: e,
                          })
                        }}
                        options={hourRangeList}
                        isError={isErrored?.hourRange}
                      />
                    </div>

                    <div className='w-2/12'>
                      <SimpleSelect
                        value={filterVariables?.meridian}
                        onChange={(e: any) => {
                          setFilterVariables({
                            ...filterVariables,
                            meridian: e,
                          })
                        }}
                        options={[
                          { label: 'AM', value: 'am' },
                          { label: 'PM', value: 'pm' },
                        ]}
                        placeholder='AM'
                        isError={isErrored?.meridian}
                      />
                    </div>
                  </div>
                </div>

                <div className='flex gap-x-3'>
                  <Button
                    onClick={() => {
                      handleApplyFilter()
                    }}>
                    Apply
                  </Button>

                  <Button
                    appearance='dull'
                    onClick={() => {
                      setFilterActive(false)
                      setIsFilterModalOpen(false)
                      setFilterVariables({
                        ...filterVariables,
                        principle: { label: 'All', value: 'All' },
                        courier: { label: 'All', value: 'All' },
                        orderDate: new Date(),
                        hourRange: '',
                        meridian: '',
                        status: { label: 'All', value: 'All' },
                      })
                      setToDate('')
                      setFromDate('')
                      getInternalHourlyReceivingOrders(
                        tableVariables.take,
                        tableVariables.skip,
                        { label: 'All', value: 'All' },
                        { label: 'All', value: 'All' },
                        '',
                        '',
                        { label: 'All', value: 'All' },
                      )
                      setPageNumber(1)

                      setIsErrored({
                        ...isErrored,
                        hourRange: false,
                        meridian: false,
                      })
                    }}>
                    Clear
                  </Button>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div
        className={CN(
          'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
        )}>
        <DataTable
          itemData={rows}
          setTableVariables={setTableVariables}
          tableVariables={tableVariables}
          totalRowCount={totalRowCount}
          getInternalHourlyReceivingOrdersIsLoading={
            getInternalHourlyReceivingOrdersIsLoading
          }
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </div>

      {/* for csv export */}
      <CSVLink
        filename='HourlyReport.csv'
        data={csvOrderData || ''}
        ref={csvLinkEl}
      />
    </div>
  )
}

HourlyReceivingOrdersReportDataTable.defaultProps = {}

export default HourlyReceivingOrdersReportDataTable
