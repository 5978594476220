/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  useGetWarehouseList,
  useGetWarehouseTable,
} from 'framework/api/methods'
import { setShippingSummeryWarehouseCodes } from 'store/reducers/order/orderSlice'

import { SimpleSelect } from 'components/atoms'
// import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'
import { OrderConsignmentStateBadge } from 'components/atoms/OrderConsignmentStateBadge'
import { OrderTypeLabel } from 'components/atoms/OrderTypeLabel'

import './ShippingSummeryPanel.scss'

export interface ShippingSummeryPanelInterface {
  fromAddress?: string | undefined
  WMSOrder?: string | undefined
  orderConsignmentState?: string | undefined
  partner?: string | undefined
  pickUpDate?: any
  toAddress?: string | undefined
  notify?: any
  warehouseDetails?: any
  setPickupWhCode?: any
  orderType?: any
}

export const ShippingSummeryPanel: FC<ShippingSummeryPanelInterface> = ({
  fromAddress,
  WMSOrder,
  orderConsignmentState,
  partner,
  // pickUpDate,
  toAddress,
  notify,
  warehouseDetails,
  setPickupWhCode,
  orderType,
}: ShippingSummeryPanelInterface) => {
  const dispatch = useDispatch()

  const [wareHouseList, setWareHouseList] = useState([] as any)
  const [wareHouse, setWarehouse] = useState<any>()
  const [warehouseFromAddress, setWarehouseFromAddress] = useState<any>(null)

  const { mutate: getWarehouseListMutate } = useGetWarehouseTable()

  /** Process to get Warehouse List for integration */
  async function getWarehouseList(): Promise<void> {
    getWarehouseListMutate(
      { take: 100, skip: 0, name: '', isActive: true },
      {
        onSuccess: ({ data: successData }: any) => {
          const tempStateList = successData?.warehouses
            ?.filter(
              (obj: any) =>
                obj.warehouseIntegration &&
                obj.warehouseIntegration.name ===
                  warehouseDetails?.warehouseIntegration?.name,
            )
            ?.map((item: any) => {
              return {
                ...item,
                value: item.code,
                label: `${item.code} - ${item.name}`,
                id: item.id,
              }
            })
          setWareHouseList(tempStateList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  /** initially get warehouse list and clear warehouse code in store */
  useEffect(() => {
    getWarehouseList()
  }, [])

  useEffect(() => {
    dispatch(setShippingSummeryWarehouseCodes(warehouseDetails?.code || null))
  }, [warehouseDetails?.code])

  /** initially set existing warehouse in drop down */
  useEffect(() => {
    const existWarehouse = wareHouseList?.find(
      (item: any) => item.id === warehouseDetails?.id,
    )

    setWarehouse(existWarehouse || '')
  }, [wareHouseList, warehouseDetails?.id])

  useEffect(() => {
    setPickupWhCode(wareHouse?.value)
  }, [wareHouse])

  /** set warehouse from address when address change */
  useEffect(() => {
    setWarehouseFromAddress(fromAddress)
  }, [fromAddress])

  return (
    <div className='shipping-summery-panel flex w-full rounded-lg bg-white px-4 pt-[14px] pb-[15px]'>
      <div className='left-container flex w-3/5 items-center '>
        <div className='map-icon-container flex flex-col justify-center items-center '>
          <i className='ri-checkbox-blank-circle-fill text-N-300 pb-1'></i>
          <div className='flex h-[40px] pb-[5.5px] border-r-2 border-r-N-300 border-dotted'></div>
          <i className='ri-map-pin-fill text-P-500'></i>
        </div>

        <div className='shipping-address-container flex flex-col pl-[26px] w-full'>
          <div className='pb-4 z-[2]'>
            <div className='flex justify-start items-center gap-x-1 w-6/12'>
              <p className='text-xs font-medium text-N-500'>From:</p>

              <div className='w-full'>
                <SimpleSelect
                  size='sm'
                  options={wareHouseList}
                  value={wareHouse}
                  onChange={(e: any) => {
                    setWarehouse({
                      value: e.value,
                      label: e.label,
                    })

                    const {
                      lineOne: fromLineOne,
                      lineTwo: fromLineTwo,
                      city: fromCity,
                      state: fromState,
                      country: fromCountry,
                      postCode: fromPostCode,
                    } = e.address || {}

                    const fullFromAddressLine =
                      (fromLineOne ? '' + fromLineOne : '') +
                      (fromLineTwo ? ', ' + fromLineTwo : '') +
                      (fromCity ? ', ' + fromCity : '') +
                      (fromState ? ', ' + fromState : '') +
                      (fromCountry ? ', ' + fromCountry : '') +
                      (fromPostCode ? ', ' + fromPostCode : '')

                    setWarehouseFromAddress(fullFromAddressLine)

                    dispatch(setShippingSummeryWarehouseCodes(e?.value))
                  }}
                />
              </div>
            </div>

            <p className='text-sm font-normal text-N-700 mt-1'>
              {warehouseFromAddress}
            </p>
          </div>

          <div>
            <p className='text-xs font-medium text-N-500'>To:</p>
            <p className='text-sm font-normal text-N-700'>{toAddress}</p>
          </div>
        </div>
      </div>

      <div className='right-container flex flex-col items-end h-full w-2/5 pt-[2px]'>
        <div className='flex items-end text-xs font-normal text-N-700 pb-2 leading-none'>
          <p>WMS Order #</p>
          <p className='text-base font-medium pl-1 leading-none'>{WMSOrder}</p>
        </div>

        <div className='flex text-sm font-normal text-N-700 pb-2'>
          <p>Partner:</p> <p className='font-medium pl-1'>{partner}</p>
        </div>

        {orderType && (
          <div className='flex items-center'>
            <p className='text-sm font-normal text-N-700 pb-2'>Order Type:</p>
            <OrderTypeLabel labelValue={orderType} />
          </div>
        )}

        <div className='flex'>
          <OrderConsignmentStateBadge
            appearance={orderConsignmentState}
            labelValue={orderConsignmentState}
          />
          {/* <p className='pr-4 pt-1 text-sm font-normal text-N-700'>
            Pickup Date:
          </p>

          <DatePickerComponent
            enabled={true}
            value={pickUpDate}
            width='112px'
            cssClass={'e-calendar-yellow'}
          /> */}
        </div>
      </div>
    </div>
  )
}

ShippingSummeryPanel.defaultProps = {
  fromAddress: undefined,
  WMSOrder: undefined,
  orderConsignmentState: undefined,
  partner: undefined,
  toAddress: undefined,
  notify: undefined,
}

export default ShippingSummeryPanel
