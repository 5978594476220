/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetPartnerOrderTypesListInterface {
  type: any[]
}

export const useGetPartnerOrderTypes = () => {
  const mutate = useMutation(({ type }: GetPartnerOrderTypesListInterface) => {
    return get(`/PartnerOrders/OrderTypeList`)
  })

  return mutate
}

export default useGetPartnerOrderTypes
