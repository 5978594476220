import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

import { timeIntoDateTimeFormat } from 'utils'

interface CreateOrderCutoffTimeImeInterface {
  partnerCode?: string
  partnerName?: string
  integrationType?: string
  integrationId?: string
  integrationURL?: string
  cutoffTime?: string
  beforeCutoffTime?: number
  afterCutoffTime?: number
  status?: boolean
}
export const useCreateOrderCutoffTime = () => {
  const mutate = useMutation(
    ({
      partnerCode,
      partnerName,
      integrationType,
      integrationId,
      integrationURL,
      cutoffTime,
      beforeCutoffTime,
      afterCutoffTime,
      status,
    }: CreateOrderCutoffTimeImeInterface) => {
      return postAPI('/CreatePartnerCutoffTime', {
        partnerCode,
        partnerName,
        integrationType,
        integrationId,
        integrationURL,
        cutofffTime: timeIntoDateTimeFormat(cutoffTime),
        beforeCutOffLeadTime: beforeCutoffTime,
        afterCutOffLeadTime: afterCutoffTime,
        status,
      })
    },
  )
  return mutate
}
export default useCreateOrderCutoffTime
