export const columns = [
  {
    customWidth: 350,
    Header: () => 'Category',
    accessor: 'category',
    Cell: ({ row }) => <div>{row.original.category}</div>,
  },
  {
    customWidth: 260,
    Header: () => 'Privilege Name',
    accessor: 'privilegeName',
    Cell: ({ row }) => <div>{row.original.privilegeName}</div>,
  },
  {
    customWidth: 500,
    Header: () => 'Privilege Description',
    accessor: 'privilegeDescription',
    Cell: ({ row }) => <div>{row.original.privilegeDescription}</div>,
  },
  {
    customWidth: 280,
    Header: () => 'Exact Name',
    accessor: 'exactName',
    Cell: ({ row }) => <div>{row.original.exactName}</div>,
  },
]

export default columns
