export const dateToStandardFormat = (incomingDate: any) => {
  const date = new Date(incomingDate)

  const dateString =
    date.getFullYear() +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2)

  const timeString =
    ('0' + date.getHours()).slice(-2) +
    ':' +
    ('0' + date.getMinutes()).slice(-2)

  return dateString + 'T' + timeString
}

export default dateToStandardFormat
