import { Button } from 'components/atoms'
import { dateToReadableString } from 'utils'

export const columns = [
  {
    customWidth: 220,
    Header: 'Manifested Date',
    accessor: 'updatedOn',
    Cell: ({ row }) => (
      <div className='flex min-w-[220px]'>
        {row?.original?.updatedOn
          ? dateToReadableString(row?.original?.updatedOn)
          : ''}
      </div>
    ),
  },
  {
    customWidth: 220,
    Header: 'Warehouse Code',
    accessor: 'warehouseCode',
    Cell: ({ row }) => (
      <div className='flex min-w-[220px]'>{row?.original?.warehouseCode}</div>
    ),
  },
  {
    customWidth: 220,
    Header: () => 'Starshipit Courier',
    accessor: 'subCourier',
    Cell: ({ row }) => (
      <div className='flex min-w-[220px]'>{row?.original?.subCourier}</div>
    ),
  },
  {
    customWidth: 220,
    Header: () => '',
    accessor: 'labelUrl',
    Cell: ({ row }) => (
      <div>
        <Button
          className='w-[10px] !px-4'
          appearance='dull'
          size='sm'
          onClick={() => {
            window.open(row.original.labelUrl, '_blank')
          }}>
          <i className='ri-download-line'></i>
        </Button>
      </div>
    ),
  },
]

export default columns
