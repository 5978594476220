/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import CN from 'classnames'

import { Button } from 'components/atoms/Button'
import { JsonLoader } from 'components/atoms/JsonLoader'

const Table = ({
  columns,
  data,
  selectedTableRows,
  setSelectedRowByButtonAction,
  getUserPrivilegesByCategoriesIsLoading,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 30,
        pageIndex: 0,
        selectedRowIds: selectedTableRows || [],
        sortBy: [
          {
            id: 'category', // Specify the id of the 'category' column
            desc: false, // Initial sorting order (true for descending, false for ascending)
          },
        ],
      },
      manualPagination: true,
      manualSortBy: false,
      autoResetPage: false,
      autoResetSelectedRows: false,
      disableSortBy: false,
    },
    useSortBy,
    usePagination,
    useRowSelect,
  )

  const slicedPageData = page

  return (
    <>
      <div className='styled-scroll min-h-[300px] max-h-full h-auto  relative overflow-y-auto rounded-md border border-N-200'>
        {getUserPrivilegesByCategoriesIsLoading && (
          <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[1] bg-opacity-10 cursor-not-allowed'>
            <JsonLoader size='xxs' />
          </div>
        )}

        <table className='w-full overflow-y-auto' {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={CN(
                      'bg-N-100 border-b border-b-N-200 sticky top-0 shadow-sm text-left pl-4 z-[1]',
                      {
                        'bg-N-200': column.isSorted,
                      },
                    )}
                    style={{ height: 45 }}
                    width={column.customWidth}>
                    <div
                      className='inline-flex items-center'
                      style={{ minHeight: 20 }}>
                      {column.render('Header')}
                      <span className='flex ml-1'>
                        {column.isSorted &&
                          (column.isSortedDesc ? (
                            <i className='ri-arrow-up-s-line' />
                          ) : (
                            <i className='ri-arrow-down-s-line' />
                          ))}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {slicedPageData.map((row, index, array) => {
              prepareRow(row)
              // Compare current category value with the previous category value
              const currentCategory = row.values['category']
              const previousCategory =
                index > 0 ? array[index - 1].values['category'] : null
              const isCategoryChange =
                index != 0 ? currentCategory !== previousCategory : true
              return (
                <React.Fragment key={index}>
                  <tr
                    className='h-11 hover:bg-N-200 cursor-pointer'
                    {...row.getRowProps()}
                    style={{
                      borderTop: isCategoryChange ? '1.5px solid black' : '0px',
                    }}
                    onClick={() => {
                      toggleAllRowsSelected(false)
                      row.toggleRowSelected()
                    }}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className={CN(
                          'border-b border-b-N-200 text-sm text-N-700 py-[11px] px-4',
                          {
                            'bg-N-300 border-b border-b-N-400': cell.isSelected,
                          },
                        )}>
                        {isCategoryChange ||
                        cell.column.id !== 'category' ||
                        cell.row.index == 0 ? (
                          cell.column.isProductImage ? (
                            <div className='flex items-center justify-between w-fit'>
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setSelectedRowByButtonAction(row.original)
                                }}
                                appearance='dull'
                                size='xs'>
                                <i className='ri-eye-line'></i>
                              </Button>
                              <Button
                                className='ml-4'
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setSelectedRowByButtonAction(row.original)
                                }}
                                appearance='dull'
                                size='xs'>
                                <i className='ri-edit-line'></i>
                              </Button>
                              <Button
                                className='ml-4'
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setSelectedRowByButtonAction(row.original)
                                }}
                                appearance='danger'
                                size='xs'>
                                <i className='ri-delete-bin-6-line'></i>
                              </Button>
                            </div>
                          ) : (
                            cell.render('Cell')
                          )
                        ) : null}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

Table.propTypes = {}

export default Table
