import { OrderStatusLabel } from 'components/atoms'
import { dateToReadableString } from 'utils'
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'

export const columns = [
  {
    customWidth: 180,
    Header: 'WMS Order Number',
    accessor: 'wmsOrderNumber',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>{row?.original?.wmsOrderNumber}</div>
    ),
  },
  {
    customWidth: 230,
    Header: 'Customer Reference',
    accessor: 'customerReference',
    Cell: ({ row }) => (
      <div className='flex min-w-[230px]'>
        {row?.original?.customerReference}
      </div>
    ),
  },
  {
    customWidth: 100,
    Header: () => 'Principle',
    accessor: 'Principle',
    Cell: ({ row }) => (
      <div className='flex min-w-[100px]'>{row?.original?.principle}</div>
    ),
  },
  {
    customWidth: 150,
    Header: () => 'Receiver',
    accessor: 'receiver',
    Cell: ({ row }) => (
      <div className='flex min-w-[150px]'>{row?.original?.receiverName}</div>
    ),
  },
  {
    customWidth: 250,
    Header: () => 'Suburb',
    accessor: 'suburb',
    Cell: ({ row }) => (
      <div className='flex min-w-[150px]'>{row?.original?.suburb}</div>
    ),
  },
  {
    customWidth: 180,
    Header: () => 'Courier',
    accessor: 'courier',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>{row?.original?.subCourier}</div>
    ),
  },
  {
    customWidth: 180,
    Header: () => 'Current States',
    accessor: 'currentStates',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>
        <OrderStatusLabel
          appearance={capitalizeFirstLetter(row?.original?.states)}
          labelValue={capitalizeFirstLetter(row?.original?.states)}
        />
      </div>
    ),
  },
  {
    customWidth: 80,
    Header: () => 'Order Date',
    accessor: 'orderDate',
    Cell: ({ row }) => (
      <div className='flex min-w-[80px]'>
        {row?.original?.orderDate
          ? dateToReadableString(row?.original?.orderDate)
          : ''}
      </div>
    ),
  },
  {
    customWidth: 80,
    Header: () => 'Consignment Date',
    accessor: 'consignmentDate',
    Cell: ({ row }) => (
      <div className='flex min-w-[80px]'>
        {row?.original?.consignmentDate
          ? dateToReadableString(row?.original?.consignmentDate)
          : ''}
      </div>
    ),
  },
  {
    customWidth: 180,
    Header: () => 'Tracking Code',
    accessor: 'trackingNo',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>{row?.original?.trackingNo}</div>
    ),
  },
]

export default columns
