/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import { useEffect, useMemo, useState } from 'react'

import columnsStructure from './columnsStructure'
import Table from './Table'

import './DataTable.scss'

export const DataTable = ({
  itemData,
  setTableVariables,
  tableVariables,
  totalRowCount,
  getInternalConsignmentExpensesIsLoading,
  pageNumber,
  setPageNumber,
  ...restProps
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const columns = useMemo(() => columnsStructure, [])

  useEffect(() => {
    itemData.length > 0 ? setData([...itemData]) : setData([])
  }, [itemData])

  return (
    <div className='data-table w-full text-sm h-full' {...restProps}>
      <Table
        data={data}
        loading={loading}
        columns={columns}
        setTableVariables={setTableVariables}
        tableVariables={tableVariables}
        totalRowCount={totalRowCount}
        getInternalConsignmentExpensesIsLoading={
          getInternalConsignmentExpensesIsLoading
        }
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
    </div>
  )
}

export default DataTable
