import { dateToReadableString } from 'utils/dateToReadableString'

export const columns = [
  {
    customWidth: 80,
    Header: 'Order Date',
    accessor: 'orderDate',
    Cell: ({ row }) => (
      <div className='flex min-w-[80px]'>
        {row?.original?.orderDate
          ? dateToReadableString(row?.original?.orderDate)
          : ''}
      </div>
    ),
  },
  {
    customWidth: 150,
    Header: 'Consignment Date',
    accessor: 'consignmentDate',
    Cell: ({ row }) => (
      <div className='flex min-w-[150px]'>
        {row?.original?.dispatchedDate
          ? dateToReadableString(row?.original?.dispatchedDate)
          : ''}
      </div>
    ),
  },
  {
    customWidth: 100,
    Header: () => 'Courier',
    accessor: 'courier',
    Cell: ({ row }) => (
      <div className='flex min-w-[100px]'>{row?.original?.courier}</div>
    ),
  },
  {
    customWidth: 100,
    Header: () => 'Principle',
    accessor: 'principle',
    Cell: ({ row }) => (
      <div className='flex min-w-[100px]'>{row?.original?.principle}</div>
    ),
  },
  {
    customWidth: 200,
    Header: () => 'Receiver Name',
    accessor: 'receiverName',
    Cell: ({ row }) => (
      <div className='flex min-w-[200px]'>{row?.original?.receiverName}</div>
    ),
  },
  {
    customWidth: 150,
    Header: () => 'Customer Reference',
    accessor: 'customerReference',
    Cell: ({ row }) => (
      <div className='flex min-w-[150px]'>
        {row?.original?.customerReference}
      </div>
    ),
  },
  {
    customWidth: 120,
    Header: () => 'Order No',
    accessor: 'orderNo',
    Cell: ({ row }) => (
      <div className='flex min-w-[120px]'>{row?.original?.orderNo}</div>
    ),
  },
  {
    customWidth: 120,
    Header: () => 'Job Reference',
    accessor: 'jobReference',
    Cell: ({ row }) => (
      <div className='flex min-w-[120px]'>{row?.original?.jobReference}</div>
    ),
  },
  {
    customWidth: 150,
    Header: () => 'Reference',
    accessor: 'reference',
    Cell: ({ row }) => (
      <div className='flex min-w-[120px]'>{row?.original?.reference}</div>
    ),
  },
  {
    customWidth: 170,
    Header: () => 'Tracking Number',
    accessor: 'trackingNumber',
    Cell: ({ row }) => (
      <div className='flex min-w-[170px]'>{row?.original?.trackingNo}</div>
    ),
  },
]

export default columns
