import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface getInternalHourlyReceivingOrdersInterface {
  take?: number
  skip?: number
  principle?: any
  courier?: any
  orderStartDate?: string | null
  orderEndDate?: string | null
  status?: any
}

export const useGetInternalHourlyReceivingOrders = () => {
  const mutate = useMutation(
    ({
      take,
      skip,
      principle,
      courier,
      orderStartDate,
      orderEndDate,
      status,
    }: getInternalHourlyReceivingOrdersInterface) => {
      return get(
        `/InternalReports/Hourly?Take=${take}&Skip=${skip}&Principle=${
          principle?.value === 'All' ? '' : principle?.value
        }&Courier=${courier?.value === 'All' ? '' : courier?.value}&Status=${
          status?.value === 'All' ? '' : status?.value
        }&OrderFrom=${orderStartDate}&OrderTo=${orderEndDate}`,
      )
    },
  )

  return mutate
}

export default useGetInternalHourlyReceivingOrders
