import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateCutoffTImeInterface {
  warehouseCode?: string
  warehouseName?: string
  courierCode?: string
  courierName?: string
  cutoffTime?: string
  subCourierName?: string
  subCourierCode?: string
}

export const useCreateCourierCutoffTime = () => {
  const mutate = useMutation(
    ({
      warehouseCode,
      warehouseName,
      courierCode,
      courierName,
      cutoffTime,
      subCourierName,
      subCourierCode,
    }: CreateCutoffTImeInterface) => {
      return postAPI('/CutoffTime', {
        warehouseCode,
        warehouseName: warehouseName?.split('- ')[1],
        courierCode,
        courierName,
        cutofffTime: cutoffTime,
        subCourierName,
        subCourierCode,
      })
    },
  )

  return mutate
}

export default useCreateCourierCutoffTime
