import { Badge } from 'components/atoms/Badge'
import { dateAndTImeToReadableString } from 'utils'

export const columns = [
  {
    customWidth: 100,
    Header: () => 'Principle',
    accessor: 'Principle',
    Cell: ({ row }) => (
      <div className='flex min-w-[100px]'>{row?.original?.principle}</div>
    ),
  },
  {
    customWidth: 120,
    Header: () => 'WMS Order No',
    accessor: 'wmsOrderNo',
    Cell: ({ row }) => (
      <div className='flex min-w-[120px]'>{row?.original?.wmsNo}</div>
    ),
  },
  {
    customWidth: 140,
    Header: () => 'Order Date and Time',
    accessor: 'orderDateAndTime',
    Cell: ({ row }) => (
      <div className='flex min-w-[140px]'>
        {row?.original?.orderDate
          ? dateAndTImeToReadableString(row?.original?.orderDate)
          : ''}
      </div>
    ),
  },
  {
    customWidth: 140,
    Header: () => 'Dispatch Date and Time',
    accessor: 'dispatchDateAndTime',
    Cell: ({ row }) => (
      <div className='flex min-w-[140px]'>
        {row?.original?.dispatchedDate
          ? dateAndTImeToReadableString(row?.original?.dispatchedDate)
          : ''}
      </div>
    ),
  },
  {
    customWidth: 125,
    Header: () => 'Courier',
    accessor: 'courier',
    Cell: ({ row }) => (
      <div className='flex min-w-[120px]'>{row?.original?.courier}</div>
    ),
  },
  {
    customWidth: 120,
    Header: () => 'Status',
    accessor: 'status',
    Cell: ({ row }) => (
      <div>
        {row.original.status === 'Dispatched on time' ? (
          <Badge appearance='warning' labelValue='Dispatched on time' />
        ) : row.original.status === 'Delayed' ? (
          <Badge appearance='error' labelValue='Delayed' />
        ) : (
          ''
        )}
      </div>
    ),
  },
]

export default columns
