import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetCutoffTableInterface {
  take?: number
  skip?: number
  name?: string
}

export const useGetCourierCutoffTable = () => {
  const mutate = useMutation(
    ({ take, skip, name }: GetCutoffTableInterface) => {
      return get(`/CutoffTime?take=${take}&skip=${skip}&search=${name}`)
    },
  )

  return mutate
}

export default useGetCourierCutoffTable
