import { useMutation } from 'react-query'
import { get, postAPI } from 'framework/api/http'

interface GetPartnerOrdersInterface {
  take?: number
  skip?: number
  Search?: string
  partner?: any
  status?: any
  from?: any
  to?: any
  isInternational?: boolean
  orderType?: any
}

export const useGetPartnerOrders = () => {
  const mutate = useMutation(
    ({
      take,
      skip,
      Search,
      status,
      partner,
      from,
      to,
      isInternational,
      orderType,
    }: GetPartnerOrdersInterface) => {
      return postAPI(`/PartnerOrders?From=${from}&To=${to}`, {
        take,
        skip,
        SearchKeyword: Search === '' ? null : Search,
        Status: status[0] === 'ALL' ? null : status,
        PartnerId: partner.value === undefined ? null : partner.value,
        isInternational,
        orderType: orderType,
      })
    },
  )

  return mutate
}

export default useGetPartnerOrders
