import React, { FC, useState } from 'react'
import CN from 'classnames'

import ReportTableView from './sections/ReportTableView'
import ReportTabView from './sections/ReportTabView'

export interface ReportsProps {
  [x: string]: any
}

export const Reports: FC<ReportsProps> = ({ className }: ReportsProps) => {
  const [isTableView, setIsTableView] = useState<any>({
    active: false,
    tab: 'Internal',
    report: 'Consignment Report',
  })

  return (
    <div
      className={CN(
        'flex items-center justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}>
      <div className='flex flex-col h-full w-full rounded-lg bg-white shadow-md'>
        {isTableView?.active ? (
          <ReportTableView
            isTableView={isTableView}
            setIsTableView={setIsTableView}
          />
        ) : (
          <ReportTabView
            tabIndex={isTableView.tab === 'Internal' ? 0 : 1}
            isTableView={isTableView}
            setIsTableView={setIsTableView}
          />
        )}
      </div>
    </div>
  )
}

Reports.defaultProps = {}

export default Reports
