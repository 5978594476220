export const dateAndTimeInToReadableTimeOnlyFormat = (timeString: any) => {
  const date = new Date(timeString)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  // Convert hours from 24-hour to 12-hour format
  const formattedHours = hours % 12 || 12
  const formattedTime = `${formattedHours < 10 ? '0' : ''}${formattedHours}:${
    minutes < 10 ? '0' : ''
  }${minutes} ${ampm}`
  return formattedTime
}
