import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface getStarshipITManifestArchiveInterface {
  take?: number
  skip?: number
  warehouse?: any
  courier?: any
  manifestedFrom?: string
  manifestedTo?: string
}

export const useGetStarshipITManifestArchive = () => {
  const mutate = useMutation(
    ({
      take,
      skip,
      warehouse,
      courier,
      manifestedFrom,
      manifestedTo,
    }: getStarshipITManifestArchiveInterface) => {
      return get(
        `/StarshipITReports/ManfestArchive?Take=${take}&Skip=${skip}&WarehouseCode=${
          warehouse?.value === 'All' ? '' : warehouse?.value
        }&SubCourier=${
          courier?.value === 'All' ? '' : courier?.value
        }&ManifestedFrom=${manifestedFrom}&ManifestedTo=${manifestedTo}`,
      )
    },
  )

  return mutate
}

export default useGetStarshipITManifestArchive
