import { FC, useEffect, useState } from 'react'
import CN from 'classnames'
import {
  useGetPartnerAllList,
  useRoleList,
  useUserActivate,
  useUserAdd,
  useUserDeactivate,
  useUserEdit,
  useVerifyUserNameValidity,
} from 'framework/api/methods'

import { Button } from 'components/atoms/Button'
import { Drawer } from 'components/atoms/Drawer'
import { Input } from 'components/atoms/Input'
import { SimpleSelect } from 'components/atoms/Select'
import { ToggleSwitch } from 'components/atoms/ToggleSwitch'
import { isEmail, isNumber } from 'utils'

export interface AddNewUserProps {
  [x: string]: any
}

export const AddNewUser: FC<AddNewUserProps> = ({
  className,
  isActive,
  userDetails,
  isAddNew,
  refetch,
  onCloseClick,
  notify,
  ...restProps
}: AddNewUserProps) => {
  const AddNewUserClasses = CN(`add-new-user`, className)

  const [roleList, setRoleList] = useState()
  const [partnerList, setPartnerList] = useState()
  const [firsName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNo, setPhoneNo] = useState('')
  const [role, setRole] = useState<any>([])
  const [partner, setPartner] = useState<any>([])
  const [status, setStatus] = useState(true)
  const [isErrorField, setIsErrorField] = useState<any>({
    firsName: false,
    lastName: false,
    email: false,
    phoneNo: false,
    role: false,
    status: false,
  })

  /** Get Roles API */
  const { isLoading: roleListLoading, data: roleListData } = useRoleList()

  /** create User API */
  const onMutateSuccess = (data: any) => {
    notify(data.message, 'success')
    refetch()
  }

  /** Get Partner API */
  const { isLoading: partnerListLoading, data: partnerListData } =
    useGetPartnerAllList()

  const onMutateError = ({ response: { data: errData } }: any) => {
    notify(errData.message || 'Error...', 'error')
  }

  const { mutate: addNewUser } = useUserAdd(onMutateSuccess, onMutateError)

  const onMutateSuccessEdit = (data: any) => {
    notify(data.message, 'success')
    refetch()
  }

  const onMutateErrorEdit = (err: any) => {
    notify(err.message || 'Error...', 'error')
  }

  const { mutate: editUser } = useUserEdit(
    onMutateSuccessEdit,
    onMutateErrorEdit,
  )

  /** user deactivate API */
  const onMutateSuccessDeactivate = (data: any) => {
    notify(data.message, 'success')
    refetch()
  }

  const onMutateErrorDeactivate = (err: any) => {
    notify(err.message || 'Error...', 'error')
  }

  const { mutate: deactivateUser } = useUserDeactivate(
    onMutateSuccessDeactivate,
    onMutateErrorDeactivate,
  )

  /** user activate API */
  const onMutateSuccessActivate = (data: any) => {
    notify(data.message, 'success')
    refetch()
  }

  const onMutateErrorActivate = (err: any) => {
    notify(err.message || 'Error...', 'error')
  }

  const { mutate: activateUser } = useUserActivate(
    onMutateSuccessActivate,
    onMutateErrorActivate,
  )

  useEffect(() => {
    if (!isAddNew) {
      setFirstName(userDetails?.firstName)
      setLastName(userDetails?.lastName)
      setEmail(userDetails?.email)
      setPhoneNo(userDetails?.phoneNumber)
      setRole({
        id: userDetails?.roleId,
        value: userDetails?.roleName,
        label: userDetails?.roleName,
      })
      setPartner({
        id: userDetails?.partnerId,
        value: userDetails?.partnerName,
        label: userDetails?.partnerName,
      })
      setStatus(userDetails?.isActive)
    } else {
      setFirstName('')
      setLastName('')
      setEmail('')
      setPhoneNo('')
      setRole([])
      setPartner([])
      setStatus(false)
    }
  }, [userDetails, isAddNew])

  useEffect(() => {
    const roles: any = []
    if (roleListData) {
      roleListData.roles?.map((role: any) => {
        roles.push({
          id: role.id,
          value: role.name,
          label: role.name,
        })
      })
    }
    setRoleList(roles)
  }, [roleListData])

  useEffect(() => {
    const partners: any = []
    if (partnerListData) {
      partnerListData.partners?.map((partner: any) => {
        partners.push({
          id: partner.id,
          value: partner.name,
          label: partner.name,
        })
      })
    }
    setPartnerList(partners)
  }, [partnerListData])

  const validateInputs = () => {
    setIsErrorField({
      ...isErrorField,
      firsName: firsName ? false : true,
      lastName: lastName ? false : true,
      phoneNo: isNumber(phoneNo) || phoneNo === '' ? false : true,
      email: isEmail(email) ? false : true,
      role: role.id ? false : true,
    })

    const isAllValidFields =
      firsName &&
      lastName &&
      (isNumber(phoneNo) || phoneNo === '') &&
      isEmail(email) &&
      role.id
    return isAllValidFields
  }

  /** Process the submission */
  const handleSubmit = (e: any) => {
    e.preventDefault()
    const valid = validateInputs()
    if (isAddNew) {
      if (valid) {
        addNewUser({
          userName: email,
          firstName: firsName,
          lastName: lastName,
          email: email,
          phoneNumber: phoneNo,
          roleId: role?.id,
          partnerId: partner?.id,
        })
        onCloseClick()
      }
    } else {
      if (valid) {
        editUser({
          id: userDetails.id,
          roleId: role?.id,
          email: email,
          firstName: firsName,
          lastName: lastName,
          phoneNumber: phoneNo,
          partnerId: partner?.id,
        })
        onCloseClick()
      }
    }
    refetch()
  }

  /** API Call for verify user name already exists react-query */
  const { mutate: getVerifyUserNameValidateMutate } =
    useVerifyUserNameValidity()

  /** Process the get User roles Table Data */
  async function getVerifyUserNameValidate() {
    getVerifyUserNameValidateMutate(
      { Username: email },
      {
        onSuccess: ({ data: successData }: any) => {
          setIsErrorField({
            ...isErrorField,
            email: false,
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          setIsErrorField({
            ...isErrorField,
            email: true,
          })
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  /** handle email on change */
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value)
  }

  /** handle email(username) verification in add user page with already existing user names  */
  useEffect(() => {
    if (isAddNew) {
      const timer = setTimeout(() => {
        email && getVerifyUserNameValidate()
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [email])

  return (
    <Drawer
      className={AddNewUserClasses}
      isActive={isActive}
      width={360}
      {...restProps}
      title={!isAddNew ? 'Edit User' : 'Add New User'}
      onCloseClick={onCloseClick}>
      <div className='p-[24px] justify-between h-full relative'>
        <div className='flex flex-col gap-y-[20px]'>
          <Input
            labelValue='First Name'
            isRequired
            type='text'
            value={firsName}
            onChange={(e: any) => setFirstName(e.target.value)}
            isError={isErrorField.firsName}
          />
          <Input
            labelValue='Last Name'
            isRequired
            type='text'
            value={lastName}
            onChange={(e: any) => setLastName(e.target.value)}
            isError={isErrorField.lastName}
          />
          <Input
            labelValue='Email'
            isRequired
            type='text'
            value={email}
            onChange={(e: any) => {
              handleEmailChange(e)
            }}
            isError={isErrorField.email}
            isDisabled={isAddNew ? false : true}
          />
          <Input
            labelValue='Phone No'
            type='text'
            value={phoneNo}
            onChange={(e: any) => setPhoneNo(e.target.value)}
            isError={isErrorField.phoneNo}
          />
          <div className='flex flex-col w-full'>
            <div className='flex'>
              <span className='pb-2 text-N-700'>Role</span>
              <span className='text-R-500 ml-[2px]'>*</span>
            </div>

            <SimpleSelect
              loading={roleListLoading}
              placeholder='Please Select Role'
              value={role}
              onChange={(selectedRole: any) => {
                setRole(selectedRole)
              }}
              options={roleList}
            />
          </div>
          <div className='flex flex-col w-full'>
            <div className='flex'>
              <span className='pb-2 text-N-700'>Partner</span>
            </div>

            <SimpleSelect
              loading={partnerListLoading}
              placeholder='Please Select Partner'
              value={partner}
              onChange={(selectedPartner: any) => {
                setPartner(selectedPartner)
              }}
              options={partnerList}
            />
          </div>
          <div className='flex flex-col w-full'>
            <span className='pb-2 text-N-700'>Status</span>
            <ToggleSwitch
              value={status}
              setValue={isAddNew ? true : status}
              isError={isErrorField.status}
              onToggleClick={(e: any) => {
                setStatus(e.target.checked)
                if (e.target.checked === false) {
                  deactivateUser({
                    id: userDetails.id,
                  })
                } else {
                  activateUser({
                    id: userDetails.id,
                  })
                }
              }}
              isDisabled={isAddNew ? true : false}
            />
          </div>
        </div>

        <div className='absolute flex items-center justify-between shadow-sm drawer-footer border-N-200 left-[24px] bottom-[12px]'>
          <Button
            disabled={role.id && firsName && lastName && email ? false : true}
            onClick={(e: any) => {
              handleSubmit(e)
            }}>
            {!isAddNew ? 'Update User' : 'Save User'}
          </Button>
          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            onClick={onCloseClick}>
            Close
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

AddNewUser.defaultProps = {}

export default AddNewUser
