/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import {
  useGetStarshipITCarriers,
  useGetStarshipITManifestArchive,
  useGetWarehouseTable,
} from 'framework/api/methods'

import { Button, SimpleSelect } from 'components/atoms'
import { dateToReadableString } from 'utils'

import { DataTable } from './DataTable'

import './datePicker.scss'

export interface ManifestReportArchiveDataTableProps {
  [x: string]: any
}

export const ManifestReportArchiveDataTable: FC<
  ManifestReportArchiveDataTableProps
> = ({ notify }: ManifestReportArchiveDataTableProps) => {
  const [rows, setRows] = useState({})
  const [tableVariables, setTableVariables] = useState({
    take: 10,
    skip: 0,
  })
  const date = new Date()
  const [pageNumber, setPageNumber] = useState(1)

  const [totalRowCount, setTotalRowCount] = useState(0)
  const [isFilterMenuOpen, setIsFilterModalOpen] = useState(false)
  const [filterActive, setFilterActive] = useState(false)
  const [filterVariables, setFilterVariables] = useState<any>({
    warehouse: { label: 'All', value: 'All' },
    courier: { label: 'All', value: 'All' },
    manifestedFrom: new Date(date.setDate(date.getDate() - 30)),
    manifestedTo: new Date(),
  })
  const [warehouseList, setWarehouseList] = useState([])
  const [starshipitCourierList, setStarshipitCourierList] = useState<any>([])

  const { mutate: getWarehouseListMutate } = useGetWarehouseTable()

  /** Process to get Warehouse List for integration */
  async function getWarehouseList(): Promise<void> {
    getWarehouseListMutate(
      { take: 100, skip: 0, name: '', isActive: true },
      {
        onSuccess: ({ data: successData }: any) => {
          const updatedWarehouseList = successData.warehouses.map(
            (item: any) => {
              return {
                value: item?.code,
                label: item?.code + ' - ' + item?.name,
              }
            },
          )
          setWarehouseList(updatedWarehouseList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }
  /** API Call for get list using react-query */
  const {
    mutate: getStarshipITManifestArchivesMutate,
    isLoading: getStarshipITManifestArchivesIsLoading,
  } = useGetStarshipITManifestArchive()

  /** Process the get table data */
  async function getStarshipITConsignmentOrders(
    take?: number,
    skip?: number,
    warehouse?: any,
    courier?: any,
    manifestedFrom?: any,
    manifestedTo?: any,
  ) {
    getStarshipITManifestArchivesMutate(
      {
        take,
        skip,
        warehouse,
        courier,
        manifestedFrom: manifestedFrom
          ? manifestedFrom.getFullYear() +
            '-' +
            ('0' + (manifestedFrom.getMonth() + 1)).slice(-2) +
            '-' +
            manifestedFrom.getDate()
          : '',
        manifestedTo: manifestedTo
          ? manifestedTo.getFullYear() +
            '-' +
            ('0' + (manifestedTo.getMonth() + 1)).slice(-2) +
            '-' +
            manifestedTo.getDate()
          : '',
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setRows(successData?.manifestFiles || [])
          setTotalRowCount(successData?.totalCount)
        },
        onError: ({ response: { data: errData } }: any) => {
          //
        },
      },
    )
  }

  const getStarshipITCarriersOnSuccess = (data: any) => {
    setStarshipitCourierList(data?.starshipitCarrierCodes || [])
  }

  /** Function On-Error Get  Curriers of starshipit */
  const getStarshipITCarriersOnError = (error: any) => {
    notify(error.message || 'Error...!', 'error')
  }

  /** API Call for Get Curriers of starshipit */
  const { refetch: getStarshipITCarriers } = useGetStarshipITCarriers(
    {},
    getStarshipITCarriersOnSuccess,
    getStarshipITCarriersOnError,
  )
  useEffect(() => {
    getStarshipITConsignmentOrders(
      tableVariables.take,
      tableVariables.skip,
      filterVariables.warehouse,
      filterVariables.courier,
      filterVariables.manifestedFrom,
      filterVariables.manifestedTo,
    )
  }, [tableVariables])

  useEffect(() => {
    getWarehouseList()
    getStarshipITCarriers()
  }, [])

  return (
    <div className='flex w-full flex-col justify-start items-center mt-10 h-full overflow-y-scroll'>
      <div className='flex justify-between items-center w-full mb-4'>
        <div className='flex items-center relative'>
          <Button
            onClick={() => {
              setIsFilterModalOpen(!isFilterMenuOpen)
            }}
            appearance={isFilterMenuOpen ? 'default' : 'outline'}>
            <i className='ri-filter-3-line pr-3'></i> Filter
          </Button>
          {filterActive && (
            <div className='w-[6px] h-[6px] bg-R-500 rounded-full absolute top-[-2px] right-[-2px]'></div>
          )}
        </div>
      </div>

      {/* expanding filter panel with open close animation */}
      <AnimatePresence initial={false}>
        {isFilterMenuOpen && (
          <motion.div
            className='flex flex-col bg-white w-full'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            transition={{ type: 'tween', duration: 0.3 }}
            variants={{
              open: { opacity: 1, y: 0, height: 'auto' },
              collapsed: { opacity: -1, y: -35, height: 0 },
            }}>
            {/** Input field of filtering section */}
            <div className='flex flex-col w-full mb-4 py-2'>
              <div className='flex w-full gap-x-5'>
                <div className='flex flex-col w-1/3 z-[2]'>
                  <span className='pb-2 text-N-700'>By Warehouse Code</span>

                  <SimpleSelect
                    value={filterVariables?.warehouse}
                    onChange={(e: any) => {
                      setFilterVariables({
                        ...filterVariables,
                        warehouse: e,
                      })
                    }}
                    options={[{ label: 'All', value: 'All' }, ...warehouseList]}
                  />
                </div>

                <div className='flex flex-col w-1/3 z-[2]'>
                  <span className='pb-2 text-N-700'>By Starshipit Courier</span>

                  <SimpleSelect
                    value={filterVariables?.courier}
                    onChange={(e: any) => {
                      setFilterVariables({
                        ...filterVariables,
                        courier: e,
                      })
                    }}
                    options={[
                      { label: 'All', value: 'All' },
                      ...starshipitCourierList,
                    ]}
                  />
                </div>

                <div className='flex flex-col w-1/3 z-[2] order-date'>
                  <span className='pb-2 text-N-700'>Manifested Date</span>

                  <DateRangePickerComponent
                    format='dd/MM/yyyy'
                    delayUpdate={true}
                    placeholder='Select a range'
                    startDate={filterVariables?.manifestedFrom}
                    endDate={filterVariables?.manifestedTo}
                    onChange={(e: any) => {
                      setFilterVariables({
                        ...filterVariables,
                        manifestedFrom: e.target.startDate,
                        manifestedTo: e.target.endDate,
                      })
                    }}
                  />
                </div>
              </div>
              <div className='flex gap-x-4 mt-6 justify-start items-end'>
                <Button
                  onClick={() => {
                    const date = new Date()

                    filterVariables.warehouse.value === 'All' &&
                    filterVariables.courier.value === 'All' &&
                    dateToReadableString(filterVariables.manifestedFrom) ===
                      dateToReadableString(
                        new Date(date.setDate(date.getDate() - 30)),
                      ) &&
                    dateToReadableString(filterVariables.manifestedTo) ===
                      dateToReadableString(new Date())
                      ? setFilterActive(false)
                      : setFilterActive(true)

                    getStarshipITConsignmentOrders(
                      tableVariables.take,
                      tableVariables.skip,
                      filterVariables.warehouse,
                      filterVariables.courier,
                      filterVariables.manifestedFrom,
                      filterVariables.manifestedTo,
                    )
                    setIsFilterModalOpen(false)
                    setPageNumber(1)
                  }}>
                  Apply
                </Button>
                <Button
                  appearance='dull'
                  onClick={() => {
                    const date = new Date()
                    const dateCon = new Date()
                    setFilterVariables({
                      warehouse: { label: 'All', value: 'All' },
                      courier: { label: 'All', value: 'All' },
                      manifestedFrom: new Date(
                        date.setDate(date.getDate() - 30),
                      ),
                      manifestedTo: new Date(),
                    })
                    setFilterActive(false)
                    setIsFilterModalOpen(false)

                    getStarshipITConsignmentOrders(
                      tableVariables.take,
                      tableVariables.skip,
                      { label: 'All', value: 'All' },
                      { label: 'All', value: 'All' },
                      new Date(date.setDate(date.getDate() - 30)),
                      new Date(),
                    )
                    setPageNumber(1)
                  }}>
                  Clear
                </Button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <div
        className={CN(
          'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
        )}>
        <DataTable
          itemData={rows}
          setTableVariables={setTableVariables}
          tableVariables={tableVariables}
          totalRowCount={totalRowCount}
          getStarshipITManifestArchivesIsLoading={
            getStarshipITManifestArchivesIsLoading
          }
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </div>
    </div>
  )
}

ManifestReportArchiveDataTable.defaultProps = {}

export default ManifestReportArchiveDataTable
