/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import {
  useGetPartnerTable,
  useGetStarshipITConsignmentOrders,
  useReportExport,
} from 'framework/api/methods'
import { Couriers } from 'static-data/couriers'

import { Button, SimpleSelect } from 'components/atoms'
import { dateToReadableString } from 'utils'

import { DataTable } from './DataTable'

import './datePicker.scss'

export interface ConsignmentOrdersReportDataTableProps {
  [x: string]: any
}

export const ConsignmentOrdersReportDataTable: FC<
  ConsignmentOrdersReportDataTableProps
> = ({ notify }: ConsignmentOrdersReportDataTableProps) => {
  const [rows, setRows] = useState({})
  const [tableVariables, setTableVariables] = useState({
    take: 10,
    skip: 0,
  })
  const date = new Date()
  const dateCon = new Date()
  const [csvOrderData, setCsvOrderData] = useState([])
  const [pageNumber, setPageNumber] = useState(1)

  const csvLinkEl = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const [totalRowCount, setTotalRowCount] = useState(0)
  const [isFilterMenuOpen, setIsFilterModalOpen] = useState(false)
  const [filterActive, setFilterActive] = useState(false)
  const [filterVariables, setFilterVariables] = useState<any>({
    principle: { label: 'All', value: 'All' },
    courier: { label: 'All', value: 'All' },
    orderStartDate: new Date(date.setDate(date.getDate() - 30)),
    orderEndDate: new Date(),
    consignmentStarDate: new Date(dateCon.setDate(dateCon.getDate() - 30)),
    consignmentEndDate: new Date(),
  })
  const [principleList, setPrincipleList] = useState([])

  /** API Call for partner list using react-query */
  const { mutate: getPartnerListMutate } = useGetPartnerTable()

  /** Process the get partner list */
  async function getPartnerList() {
    getPartnerListMutate(
      { take: 100, skip: 0, Search: '' },
      {
        onSuccess: ({ data: successData }: any) => {
          const partners = successData.partners.map((principle: any) => {
            return {
              value: principle.code,
              label: principle.name,
            }
          })
          setPrincipleList(partners)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData?.message || 'Error...', 'error')
        },
      },
    )
  }
  /** API Call for get list using react-query */
  const {
    mutate: getStarshipITConsignmentOrdersMutate,
    isLoading: getStarshipITConsignmentOrdersIsLoading,
  } = useGetStarshipITConsignmentOrders()

  /** Process the get table data */
  async function getStarshipITConsignmentOrders(
    take?: number,
    skip?: number,
    principle?: any,
    courier?: any,
    ordStartDate?: any,
    ordEndDate?: any,
    conStartDate?: any,
    conEndDate?: any,
  ) {
    getStarshipITConsignmentOrdersMutate(
      {
        take,
        skip,
        principle,
        courier,
        orderStartDate: ordStartDate
          ? ordStartDate.getFullYear() +
            '-' +
            ('0' + (ordStartDate.getMonth() + 1)).slice(-2) +
            '-' +
            ordStartDate.getDate()
          : '',
        orderEndDate: ordEndDate
          ? ordEndDate.getFullYear() +
            '-' +
            ('0' + (ordEndDate.getMonth() + 1)).slice(-2) +
            '-' +
            ordEndDate.getDate()
          : '',
        consignmentStarDate: conStartDate
          ? conStartDate.getFullYear() +
            '-' +
            ('0' + (conStartDate.getMonth() + 1)).slice(-2) +
            '-' +
            conStartDate.getDate()
          : '',
        consignmentEndDate: conEndDate
          ? conEndDate.getFullYear() +
            '-' +
            ('0' + (conEndDate.getMonth() + 1)).slice(-2) +
            '-' +
            conEndDate.getDate()
          : '',
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setRows(successData?.reports || [])
          setTotalRowCount(successData?.totalCount)
        },
        onError: ({ response: { data: errData } }: any) => {
          //
        },
      },
    )
  }

  useEffect(() => {
    getStarshipITConsignmentOrders(
      tableVariables.take,
      tableVariables.skip,
      filterVariables.principle,
      filterVariables.courier,
      filterVariables.orderStartDate,
      filterVariables.orderEndDate,
      filterVariables.consignmentStarDate,
      filterVariables.consignmentEndDate,
    )
  }, [tableVariables])

  useEffect(() => {
    getPartnerList()
  }, [])

  /** API Call export pdf/csv using react-query */
  const { mutate: reportExportMutate } = useReportExport()

  /** Process the export csv/pdf*/
  async function reportExport(exportType: string) {
    reportExportMutate(
      {
        exportType,
        reportType: 'StarShipit',
        module: 'ConsignmentOrderReport',
        consignmentOrderRequest: {
          principle:
            filterVariables?.principle?.value === 'All'
              ? null
              : filterVariables?.principle?.value,
          subCourier:
            filterVariables.courier?.value === 'All'
              ? null
              : filterVariables.courier?.value,
          orderDateFrom: filterVariables.orderStartDate
            ? filterVariables.orderStartDate.getFullYear() +
              '-' +
              ('0' + (filterVariables.orderStartDate.getMonth() + 1)).slice(
                -2,
              ) +
              '-' +
              ('0' + filterVariables.orderStartDate.getDate()).slice(-2)
            : '',
          orderDateTo: filterVariables.orderEndDate
            ? filterVariables.orderEndDate.getFullYear() +
              '-' +
              ('0' + (filterVariables.orderEndDate.getMonth() + 1)).slice(-2) +
              '-' +
              ('0' + filterVariables.orderEndDate.getDate()).slice(-2)
            : '',
          consignmentDateFrom: filterVariables.consignmentStarDate
            ? filterVariables.consignmentStarDate.getFullYear() +
              '-' +
              (
                '0' +
                (filterVariables.consignmentStarDate.getMonth() + 1)
              ).slice(-2) +
              '-' +
              ('0' + filterVariables.consignmentStarDate.getDate()).slice(-2)
            : '',
          consignmentDateTo: filterVariables.consignmentEndDate
            ? filterVariables.consignmentEndDate.getFullYear() +
              '-' +
              ('0' + (filterVariables.consignmentEndDate.getMonth() + 1)).slice(
                -2,
              ) +
              '-' +
              ('0' + filterVariables.consignmentEndDate.getDate()).slice(-2)
            : '',
        },
      },
      {
        onSuccess: ({ data: successData }: any) => {
          if (exportType === 'CSV') {
            /** set data to csv document */
            setCsvOrderData(successData)

            /** trigger csv element */
            csvLinkEl?.current?.link.click()
          }
          if (exportType === 'PDF') {
            const url = window.URL.createObjectURL(new Blob([successData]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
              'download',
              `StarshipITConsignmentOrderReport.pdf`,
            ) //or any other extension
            document.body.appendChild(link)
            link.click()
          }
        },
        onError: ({ response: { data: errData } }: any) => {
          //
        },
      },
    )
  }

  return (
    <div className='flex w-full flex-col justify-start items-center mt-10 h-full overflow-y-scroll'>
      <div className='flex justify-between items-center w-full mb-4'>
        <div className='flex items-center relative'>
          <Button
            onClick={() => {
              setIsFilterModalOpen(!isFilterMenuOpen)
            }}
            appearance={isFilterMenuOpen ? 'default' : 'outline'}>
            <i className='ri-filter-3-line pr-3'></i> Filter
          </Button>
          {filterActive && (
            <div className='w-[6px] h-[6px] bg-R-500 rounded-full absolute top-[-2px] right-[-2px]'></div>
          )}
        </div>

        <div className='flex items-center gap-x-3'>
          <Button
            appearance='dull'
            onClick={() => {
              reportExport('PDF')
            }}>
            Export PDF
          </Button>
          <Button
            appearance='dull'
            onClick={() => {
              reportExport('CSV')
            }}>
            Export CSV
          </Button>
        </div>
      </div>

      {/* expanding filter panel with open close animation */}
      <AnimatePresence initial={false}>
        {isFilterMenuOpen && (
          <motion.div
            className='flex flex-col bg-white w-full'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            transition={{ type: 'tween', duration: 0.3 }}
            variants={{
              open: { opacity: 1, y: 0, height: 'auto' },
              collapsed: { opacity: -1, y: -35, height: 0 },
            }}>
            {/** Input field of filtering section */}
            <div className='flex flex-col w-full mb-4 py-2'>
              <div className='flex w-full gap-x-5'>
                <div className='flex flex-col w-1/3 z-[3]'>
                  <span className='pb-2 text-N-700'>By Principle</span>

                  <SimpleSelect
                    value={filterVariables?.principle}
                    onChange={(e: any) => {
                      setFilterVariables({
                        ...filterVariables,
                        principle: e,
                      })
                    }}
                    options={[{ label: 'All', value: 'All' }, ...principleList]}
                  />
                </div>

                <div className='flex flex-col w-1/3 z-[3]'>
                  <span className='pb-2 text-N-700'>By Courier</span>

                  <SimpleSelect
                    value={filterVariables?.courier}
                    onChange={(e: any) => {
                      setFilterVariables({
                        ...filterVariables,
                        courier: e,
                      })
                    }}
                    options={[{ label: 'All', value: 'All' }, ...Couriers]}
                  />
                </div>

                <div className='flex flex-col w-1/3 z-[3] order-date'>
                  <span className='pb-2 text-N-700'>Order Date</span>

                  <DateRangePickerComponent
                    format='dd/MM/yyyy'
                    delayUpdate={true}
                    placeholder='Select a range'
                    startDate={filterVariables?.orderStartDate}
                    endDate={filterVariables?.orderEndDate}
                    onChange={(e: any) => {
                      setFilterVariables({
                        ...filterVariables,
                        orderStartDate: e.target.startDate,
                        orderEndDate: e.target.endDate,
                      })
                    }}
                  />
                </div>
              </div>
              <div className='flex gap-x-4 mt-6 justify-start items-end'>
                <div className='flex flex-col w-1/3 z-[2] order-date'>
                  <span className='pb-2 text-N-700'>Consignment Date</span>

                  <DateRangePickerComponent
                    format='dd/MM/yyyy'
                    delayUpdate={true}
                    placeholder='Select a range'
                    startDate={filterVariables?.consignmentStarDate}
                    endDate={filterVariables?.consignmentEndDate}
                    onChange={(e: any) => {
                      setFilterVariables({
                        ...filterVariables,
                        consignmentStarDate: e.target.startDate,
                        consignmentEndDate: e.target.endDate,
                      })
                    }}
                  />
                </div>

                <div className='flex pb-1 gap-x-3'>
                  <Button
                    onClick={() => {
                      const date = new Date()
                      const dateCon = new Date()

                      filterVariables.principle.value === 'All' &&
                      filterVariables.courier.value === 'All' &&
                      dateToReadableString(filterVariables.orderStartDate) ===
                        dateToReadableString(
                          new Date(date.setDate(date.getDate() - 30)),
                        ) &&
                      dateToReadableString(filterVariables.orderEndDate) ===
                        dateToReadableString(new Date()) &&
                      dateToReadableString(
                        filterVariables.consignmentStarDate,
                      ) ===
                        dateToReadableString(
                          new Date(dateCon.setDate(dateCon.getDate() - 30)),
                        ) &&
                      dateToReadableString(
                        filterVariables.consignmentEndDate,
                      ) === dateToReadableString(new Date())
                        ? setFilterActive(false)
                        : setFilterActive(true)

                      getStarshipITConsignmentOrders(
                        tableVariables.take,
                        tableVariables.skip,
                        filterVariables.principle,
                        filterVariables.courier,
                        filterVariables.orderStartDate,
                        filterVariables.orderEndDate,
                        filterVariables.consignmentStarDate,
                        filterVariables.consignmentEndDate,
                      )
                      setIsFilterModalOpen(false)
                      setPageNumber(1)
                    }}>
                    Apply
                  </Button>
                  <Button
                    appearance='dull'
                    onClick={() => {
                      const date = new Date()
                      const dateCon = new Date()
                      setFilterVariables({
                        principle: { label: 'All', value: 'All' },
                        courier: { label: 'All', value: 'All' },
                        orderStartDate: new Date(
                          date.setDate(date.getDate() - 30),
                        ),
                        orderEndDate: new Date(),
                        consignmentStarDate: new Date(
                          dateCon.setDate(dateCon.getDate() - 30),
                        ),
                        consignmentEndDate: new Date(),
                      })
                      setFilterActive(false)
                      setIsFilterModalOpen(false)

                      getStarshipITConsignmentOrders(
                        tableVariables.take,
                        tableVariables.skip,
                        { label: 'All', value: 'All' },
                        { label: 'All', value: 'All' },
                        new Date(date.setDate(date.getDate() - 30)),
                        new Date(),
                        new Date(dateCon.setDate(dateCon.getDate() - 30)),
                        new Date(),
                      )
                      setPageNumber(1)
                    }}>
                    Clear
                  </Button>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <div
        className={CN(
          'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
        )}>
        <DataTable
          itemData={rows}
          setTableVariables={setTableVariables}
          tableVariables={tableVariables}
          totalRowCount={totalRowCount}
          getStarshipITConsignmentOrdersIsLoading={
            getStarshipITConsignmentOrdersIsLoading
          }
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />{' '}
      </div>

      <CSVLink
        filename='ConsignmentOrderReport.csv'
        data={csvOrderData}
        ref={csvLinkEl}
      />
    </div>
  )
}

ConsignmentOrdersReportDataTable.defaultProps = {}

export default ConsignmentOrdersReportDataTable
