import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface manifestByManifestIdsInterface {
  ids?: any
  subCourier?: string
  warehouseCode?: string
  courierCode?: string
}

export const useManifestByManifestIds = () => {
  const mutate = useMutation(
    ({
      ids,
      subCourier,
      warehouseCode,
      courierCode,
    }: manifestByManifestIdsInterface) => {
      return postAPI('/Manifests/MachShipOrders', {
        ids,
        subCourier,
        warehouseCode,
        courierCode,
      })
    },
  )

  return mutate
}

export default useManifestByManifestIds
