import React, { FC } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import {
  consignmentExpense,
  consignmentOrders,
  courierExpenditure,
  dailyManifestation,
  hourlyReceivingOrders,
  manifestReportsArchive,
} from 'static-data/reports'

import { Button } from 'components/atoms'

import { ConsignmentExpensesReportDataTable } from '../ReportDataTables/internalReport/ConsignmentExpensesReportDataTable'
import { ConsignmentOrdersReportDataTable as InternalConsignmentOrdersReportDataTable } from '../ReportDataTables/internalReport/ConsignmentOrdersReportDataTable'
import { HourlyReceivingOrdersReportDataTable } from '../ReportDataTables/internalReport/HourlyReceivingOrdersReportDataTable'
import { ConsignmentOrdersReportDataTable as StarshipItConsignmentOrdersReportDataTable } from '../ReportDataTables/starshipitReports/ConsignmentOrdersReportDataTable'
import { CourierExpenditureOrdersReportDataTable } from '../ReportDataTables/starshipitReports/CourierExpenditureOrdersReportDataTable'
import { DailyManifestationOrdersReportDataTable } from '../ReportDataTables/starshipitReports/DailyManifestationOrdersReportDataTable'
import { ManifestReportArchiveDataTable } from '../ReportDataTables/starshipitReports/ManifestReportArchiveDataTable/ManifestReportArchiveDataTable'

export interface ReportTableViewProps {
  [x: string]: any
}

export const ReportTableView: FC<ReportTableViewProps> = ({
  className,
  isTableView,
  setIsTableView,
}: ReportTableViewProps) => {
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const ComponentSwitcher = () => {
    switch (isTableView?.report) {
      case consignmentOrders:
        return isTableView?.tab === 'Internal' ? (
          <InternalConsignmentOrdersReportDataTable notify={notify} />
        ) : (
          <StarshipItConsignmentOrdersReportDataTable notify={notify} />
        )
      case hourlyReceivingOrders:
        return <HourlyReceivingOrdersReportDataTable notify={notify} />
      case consignmentExpense:
        return <ConsignmentExpensesReportDataTable notify={notify} />
      case courierExpenditure:
        return <CourierExpenditureOrdersReportDataTable notify={notify} />
      case dailyManifestation:
        return <DailyManifestationOrdersReportDataTable notify={notify} />
      case manifestReportsArchive:
        return <ManifestReportArchiveDataTable notify={notify} />
      default:
        return null
    }
  }

  return (
    <div className='flex w-full flex-col justify-start items-center px-4 pt-6 h-full'>
      <div className='flex w-full justify-between items-center'>
        <div className='text-xl font-semibold'>
          {isTableView?.report === hourlyReceivingOrders
            ? 'Hourly Order Receiving and Dispatch Analysis (Based on Courier Cutoff)'
            : isTableView?.report}{' '}
          {isTableView?.report !== manifestReportsArchive &&
            isTableView?.report !== hourlyReceivingOrders &&
            `Report`}
        </div>

        <Button
          onClick={() => {
            setIsTableView({
              ...isTableView,
              report: '',
              active: false,
            })
          }}
          size='xs'
          appearance='outline'>
          <i className='ri-arrow-left-line pr-3'></i> Back to Report List
        </Button>
      </div>
      {ComponentSwitcher()}

      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        className='toast-container'
      />
    </div>
  )
}

ReportTableView.defaultProps = {}

export default ReportTableView
