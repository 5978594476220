import { Badge } from 'components/atoms'
import { dateToReadableString } from 'utils'

export const columns = [
  {
    customWidth: 250,
    Header: 'WMS Order Number',
    accessor: 'wmsOrderNumber',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>{row?.original?.wmsOrderNumber}</div>
    ),
  },
  {
    customWidth: 250,
    Header: 'Customer Reference',
    accessor: 'customerReference',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>
        {row?.original?.customerReference}
      </div>
    ),
  },
  {
    customWidth: 120,
    Header: () => 'Principle',
    accessor: 'principle',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>{row?.original?.principle}</div>
    ),
  },
  {
    customWidth: 120,
    Header: () => 'Receiver',
    accessor: 'receiverName',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>{row?.original?.receiverName}</div>
    ),
  },
  {
    customWidth: 120,
    Header: () => 'Suburb',
    accessor: 'suburb',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>{row?.original?.suburb}</div>
    ),
  },
  {
    customWidth: 120,
    Header: () => 'Courier',
    accessor: 'subCourier',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>{row?.original?.subCourier}</div>
    ),
  },
  {
    customWidth: 220,
    Header: () => 'Order Date',
    accessor: 'orderDate',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>
        {row?.original?.orderDate
          ? dateToReadableString(row?.original?.orderDate)
          : ''}
      </div>
    ),
  },
  {
    customWidth: 220,
    Header: () => 'Consignment Date',
    accessor: 'consignmentDate',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>
        {row?.original?.consignmentDate
          ? dateToReadableString(row?.original?.consignmentDate)
          : ''}
      </div>
    ),
  },
  {
    customWidth: 150,
    Header: () => 'Manifest Status',
    accessor: 'manifestationStatus',
    Cell: ({ row }) => (
      <div>
        {row.original.manifestationStatus ? (
          <Badge appearance='success' labelValue='Manifested' />
        ) : (
          <Badge appearance='warning' labelValue='Pending' />
        )}
      </div>
    ),
  },
  {
    customWidth: 220,
    Header: () => 'Tracking Code',
    accessor: 'trackingNo',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>{row?.original?.trackingNo}</div>
    ),
  },
]

export default columns
