import { dateToReadableString } from 'utils'

export const columns = [
  {
    customWidth: 230,
    Header: 'WMS Order Number',
    accessor: 'whOrderNo',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>{row?.original?.whOrderNo}</div>
    ),
  },
  {
    customWidth: 230,
    Header: 'Customer Reference',
    accessor: 'customerReference',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>
        {row?.original?.customerReference}
      </div>
    ),
  },
  {
    customWidth: 220,
    Header: () => 'principle',
    accessor: 'principle',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>{row?.original?.principle}</div>
    ),
  },
  {
    customWidth: 220,
    Header: () => 'Suburb',
    accessor: 'suburb',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>{row?.original?.suburb}</div>
    ),
  },
  {
    customWidth: 220,
    Header: () => 'Courier',
    accessor: 'courier',
    Cell: ({ row }) => (
      <div className='flex min-w-[180px]'>{row?.original?.courier}</div>
    ),
  },
  {
    customWidth: 220,
    Header: () => 'Order Date',
    accessor: 'orderDate',
    Cell: ({ row }) => (
      <div className='flex min-w-[120px]'>
        {row?.original?.orderDate
          ? dateToReadableString(row?.original?.orderDate)
          : ''}
      </div>
    ),
  },
  {
    customWidth: 220,
    Header: () => 'Consignment Date',
    accessor: 'dispatchedDate',
    Cell: ({ row }) => (
      <div className='flex min-w-[120px]'>
        {row?.original?.dispatchedDate
          ? dateToReadableString(row?.original?.dispatchedDate)
          : ''}
      </div>
    ),
  },
  {
    customWidth: 150,
    Header: () => 'Expenditure',
    accessor: 'expenditure',
    Cell: ({ row }) => (
      <div className='flex min-w-[150px]'>{row?.original?.expenditure}</div>
    ),
  },
]

export default columns
