import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateShipmentConsignmentInterface {
  wereHouseOrderId?: string
  shipmentId?: string
  quoteType?: any
  productCode?: string
  ATLValue?: boolean
  personToLift?: number
  pickUpDate?: any
  starshipITCourier?: any
  warehouseCode?: any
  productName?: any
}

export const useCreateShipmentConsignment = () => {
  const mutate = useMutation(
    ({
      wereHouseOrderId,
      shipmentId,
      quoteType,
      productCode,
      ATLValue,
      personToLift,
      pickUpDate,
      starshipITCourier,
      warehouseCode,
      productName,
    }: CreateShipmentConsignmentInterface) => {
      let apiQuery = ''
      let apiBody = {}
      const dateValue = new Date(pickUpDate)
      const date =
        dateValue.getFullYear() +
        '-' +
        ('0' + (dateValue.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + dateValue.getDate()).slice(-2)

      if (quoteType === 'ALLIED') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/Consignment/ALLIED`
      } else if (quoteType === 'AUSPOST') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/Consignment/AusPost`
      } else if (quoteType === 'AUSPOST_INTERNATIONAL') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/Consignment/AusPostInternational`
      } else if (quoteType === 'DIRECT') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/Consignment/DirectExpress`
      } else if (quoteType === 'DSE') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/Consignment/DSE`
      } else if (quoteType === 'MACHSHIP') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/Consignment/MachShip`
      } else if (quoteType === 'STARSHIPIT') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/Consignment/StarShipit`
      } else if (quoteType === 'SMART/SEND') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/Consignment/SmartSend`
      } else if (quoteType === 'CustomerPickup') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/Consignment/CustomerPickUp`
      }

      if (
        quoteType !== 'DSE' &&
        quoteType !== 'STARSHIPIT' &&
        quoteType !== 'MACHSHIP' &&
        quoteType !== 'CustomerPickup'
      ) {
        apiBody = {
          atl: ATLValue,
          productCode: productCode,
          pickupDate: date,
          warehouseCode,
        }
      } else if (quoteType === 'STARSHIPIT') {
        //NOTE: as per the backend need to send the carrier code of the starShipIT to get currier code after consign for display courier logos
        apiBody = {
          atl: ATLValue,
          productCode: productCode,
          pickupDate: date,
          carrierCode: starshipITCourier,
          warehouseCode,
        }
      } else if (quoteType === 'MACHSHIP') {
        //NOTE: as per the backend need to send the carrier code of the Machship to get currier code after consign for display courier logos
        apiBody = {
          atl: ATLValue,
          productCode: productCode,
          pickupDate: date,
          warehouseCode,
          productName: productName,
        }
      } else if (quoteType === 'CustomerPickup') {
        apiBody = {
          PickupDate: date,
        }
      } else {
        apiBody = {
          atl: ATLValue,
          personsLift: personToLift,
          specialInstructions: '',
          pickupDate: date,
          warehouseCode,
        }
      }
      return postAPI(apiQuery || '', apiBody)
    },
  )

  return mutate
}

export default useCreateShipmentConsignment
