import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface ReportExportInterface {
  exportType?: string
  reportType?: string
  module?: string
  hourlyRequest?: any
  orderRequest?: any
  consignmentOrderRequest?: any
  dailyManifestationOrderRequest?: any
  courierExpenditureRequest?: any
}

export const useReportExport = () => {
  const mutate = useMutation(
    ({
      exportType,
      reportType,
      module,
      hourlyRequest,
      orderRequest,
      consignmentOrderRequest,
      dailyManifestationOrderRequest,
      courierExpenditureRequest,
    }: ReportExportInterface) => {
      let body = {}

      if (reportType === 'Internal' && module === 'HourlyReport') {
        body = {
          exportType,
          reportType,
          module,
          hourlyRequest,
        }
      } else if (
        reportType === 'Internal' &&
        module === 'ConsignmentOrderReport'
      ) {
        body = {
          exportType,
          reportType,
          module,
          orderRequest,
        }
      } else if (
        reportType === 'Internal' &&
        module === 'ConsignmentExpenseReport'
      ) {
        body = {
          exportType,
          reportType,
          module,
        }
      } else if (
        reportType === 'StarShipit' &&
        module === 'ConsignmentOrderReport'
      ) {
        body = {
          exportType,
          reportType,
          module,
          consignmentOrderRequest,
        }
      } else if (
        reportType === 'StarShipit' &&
        module === 'DailyManifestationReport'
      ) {
        body = {
          exportType,
          reportType,
          module,
          dailyManifestationOrderRequest,
        }
      } else if (
        reportType === 'StarShipit' &&
        module === 'ConsignmentExpenseReport'
      ) {
        body = {
          exportType,
          reportType,
          module,
          courierExpenditureRequest,
        }
      } else {
        body = {}
      }

      return postAPI(
        '/FileExport',
        {
          ...body,
        },
        {
          responseType: exportType === 'PDF' && 'blob',
          headers: {
            'Content-Type': 'application/json; application/pdf',
          },
        },
      )
    },
  )

  return mutate
}

export default useReportExport
