/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import CN from 'classnames'
import {
  useGetInternalConsignmentExpenses,
  useReportExport,
} from 'framework/api/methods'

import { Button } from 'components/atoms'

import { DataTable } from './DataTable'

import './datePicker.scss'

export interface ConsignmentExpensesReportDataTableProps {
  [x: string]: any
}

export const ConsignmentExpensesReportDataTable: FC<
  ConsignmentExpensesReportDataTableProps
> = ({ className, notify }: ConsignmentExpensesReportDataTableProps) => {
  const [rows, setRows] = useState<any>({})
  const [tableVariables, setTableVariables] = useState({
    take: 10,
    skip: 0,
  })
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [csvOrderData, setCsvOrderData] = useState([])
  const [pageNumber, setPageNumber] = useState(1)

  const csvLinkEl = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  /** API Call for get list using react-query */
  const {
    mutate: getInternalConsignmentExpensesMutate,
    isLoading: getInternalConsignmentExpensesIsLoading,
  } = useGetInternalConsignmentExpenses()

  /** Process the get table data */
  async function getInternalConsignmentExpenses() {
    getInternalConsignmentExpensesMutate(
      {
        take: tableVariables?.take,
        skip: tableVariables?.skip,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setRows(successData?.reports || [])
          setTotalRowCount(successData?.totalCount)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData?.message || 'Error...', 'error')
        },
      },
    )
  }

  useEffect(() => {
    getInternalConsignmentExpenses()
  }, [tableVariables])

  /** API Call export pdf/csv using react-query */
  const { mutate: reportExportMutate } = useReportExport()

  /** Process the export csv/pdf*/
  async function reportExport(exportType: string) {
    reportExportMutate(
      {
        exportType,
        reportType: 'Internal',
        module: 'ConsignmentExpenseReport',
      },
      {
        onSuccess: ({ data: successData }: any) => {
          if (exportType === 'CSV') {
            /** set data to csv document */
            setCsvOrderData(successData)

            /** trigger csv element */
            csvLinkEl?.current?.link.click()
          }
          if (exportType === 'PDF') {
            const url = window.URL.createObjectURL(new Blob([successData]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `ConsignmentExpenseReport.pdf`) //or any other extension
            document.body.appendChild(link)
            link.click()
          }
        },
        onError: ({ response: { data: errData } }: any) => {
          //
        },
      },
    )
  }
  return (
    <div className='flex w-full flex-col justify-start items-center mt-10 h-full overflow-y-scroll'>
      <div className='flex justify-between items-center w-full mb-4'>
        <div className='flex items-center relative'></div>

        <div className='flex items-center gap-x-3'>
          <Button
            appearance='dull'
            onClick={() => {
              reportExport('PDF')
            }}>
            Export PDF
          </Button>
          <Button
            appearance='dull'
            onClick={() => {
              reportExport('CSV')
            }}>
            Export CSV
          </Button>
        </div>
      </div>

      <div
        className={CN(
          'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
        )}>
        <DataTable
          itemData={rows}
          setTableVariables={setTableVariables}
          tableVariables={tableVariables}
          totalRowCount={totalRowCount}
          getInternalConsignmentExpensesIsLoading={
            getInternalConsignmentExpensesIsLoading
          }
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </div>

      {/* for csv export */}
      <CSVLink
        filename='ConsignmentExpenseReport.csv'
        data={csvOrderData}
        ref={csvLinkEl}
      />
    </div>
  )
}

ConsignmentExpensesReportDataTable.defaultProps = {}

export default ConsignmentExpensesReportDataTable
