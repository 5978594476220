import { FC, useEffect, useState } from 'react'
import CN from 'classnames'
import {
  useGetStarshipITCarriers,
  useUpdateOrderCutoffTime,
} from 'framework/api/methods'

import { Input } from 'components/atoms'
import { Button } from 'components/atoms/Button'
import { Drawer } from 'components/atoms/Drawer'
import { SimpleSelect } from 'components/atoms/Select'
import { ToggleSwitch } from 'components/atoms/ToggleSwitch'
import { dateAndTimeInToReadableTimeOnlyFormat } from 'utils'

export interface UpdateOrderCutOffTimeProps {
  className?: string | undefined
  [x: string]: any
}
export const UpdateOrderCutOffTime: FC<UpdateOrderCutOffTimeProps> = ({
  className,
  notify,
  isActive,
  onCloseClick,
  partnerList,
  selectedRowByButtonAction,
  getCutOffTimeList,
}: UpdateOrderCutOffTimeProps) => {
  const CutOffTimeClasses = CN(
    'UpdateOrderCutOff flex flex-col py-4 px-5 relative h-full overflow-y-auto overscroll-y-auto styled-scroll gap-y-5',
    className,
    {},
  )
  const [starshipitCourierList, setStarshipitCourierList] = useState<any>([])
  const [cutoffTimeDetails, setCutoffTimeDetails] = useState<any>({
    id: '',
    partnerCode: '',
    partnerName: '',
    integrationType: '',
    integrationId: '',
    integrationURL: '',
    minutes: null,
    seconds: null,
    meridian: { label: 'AM', value: 'AM' },
    beforeCutoffTime: 0,
    afterCutoffTime: 0,
    status: true,
  })
  const [isErrorField, setIsErrorField] = useState<any>({
    partnerName: false,
    integrationType: false,
    minutes: false,
    seconds: false,
    meridian: false,
    beforeCutoffTime: false,
    afterCutoffTime: false,
  })
  useEffect(() => {
    const {
      id,
      partnerCode,
      partnerName,
      integrationType,
      integrationId,
      integrationURL,
      cutofffTime,
      beforeCutOffLeadTime,
      afterCutOffLeadTime,
      status,
    } = selectedRowByButtonAction || {}
    const formattedCutOffTime =
      dateAndTimeInToReadableTimeOnlyFormat(cutofffTime)
    setCutoffTimeDetails({
      ...cutoffTimeDetails,
      id,
      partnerCode,
      partnerName,
      integrationType: integrationURL
        ? integrationType + ` - ` + integrationURL
        : integrationType,
      integrationId,
      minutes: {
        label: formattedCutOffTime?.split(':')[0],
        value: formattedCutOffTime?.split(':')[0],
      },
      seconds: {
        label: formattedCutOffTime?.split(':')[1].split(' ')[0],
        value: formattedCutOffTime?.split(':')[1].split(' ')[0],
      },
      meridian: {
        label: formattedCutOffTime?.split(' ')[1],
        value: formattedCutOffTime?.split(' ')[1],
      },
      beforeCutoffTime: beforeCutOffLeadTime,
      afterCutoffTime: afterCutOffLeadTime,
      status,
    })
  }, [selectedRowByButtonAction])
  const {
    mutate: updateCutoffTimeMutate,
    isLoading: updateCutoffTimeIsLoading,
  } = useUpdateOrderCutoffTime()
  /** Process the Update cutoff time data */
  async function updateCutoffTime() {
    updateCutoffTimeMutate(
      {
        id: cutoffTimeDetails?.id,
        partnerCode: cutoffTimeDetails?.partnerCode,
        partnerName: cutoffTimeDetails?.partnerName,
        integrationType: cutoffTimeDetails?.integrationType,
        integrationId: cutoffTimeDetails?.integrationId,
        integrationURL: cutoffTimeDetails?.integrationURL || null,
        cutoffTime: `${cutoffTimeDetails?.minutes?.value}:${cutoffTimeDetails?.seconds?.value} ${cutoffTimeDetails?.meridian?.value}`,
        beforeCutoffTime: parseInt(cutoffTimeDetails?.beforeCutoffTime),
        afterCutoffTime: parseInt(cutoffTimeDetails?.afterCutoffTime),
        status: cutoffTimeDetails?.status,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
          onCloseClick()
          getCutOffTimeList()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }
  const validateInputs = () => {
    const {
      partnerName,
      integrationType,
      minutes,
      seconds,
      meridian,
      beforeCutoffTime,
      afterCutoffTime,
    } = cutoffTimeDetails
    setIsErrorField({
      ...isErrorField,
      partnerName: partnerName ? false : true,
      integrationType: integrationType ? false : true,
      minutes: minutes ? false : true,
      seconds: seconds ? false : true,
      meridian: meridian ? false : true,
      beforeCutoffTime:
        beforeCutoffTime === 0 || beforeCutoffTime ? false : true,
      afterCutoffTime: afterCutoffTime === 0 || afterCutoffTime ? false : true,
    })
    const isAllValidFields =
      partnerName &&
      integrationType &&
      minutes &&
      seconds &&
      meridian &&
      (beforeCutoffTime === 0 || beforeCutoffTime) &&
      (afterCutoffTime === 0 || afterCutoffTime)
    return isAllValidFields
  }
  const handleUpdateClick = () => {
    const valid = validateInputs()
    if (valid) {
      updateCutoffTime()
    }
  }
  const getStarshipITCarriersOnSuccess = (data: any) => {
    setStarshipitCourierList(data?.starshipitCarrierCodes || [])
  }
  /** Function On-Error Get  Curriers of starshipit */
  const getStarshipITCarriersOnError = (error: any) => {
    notify(error.message || 'Error...!', 'error')
  }
  /** API Call for Get Curriers of starshipit */
  const { refetch: getStarshipITCarriers } = useGetStarshipITCarriers(
    {},
    getStarshipITCarriersOnSuccess,
    getStarshipITCarriersOnError,
  )
  useEffect(() => {
    isActive && getStarshipITCarriers()
  }, [isActive])
  return (
    <Drawer
      isActive={isActive}
      onCloseClick={onCloseClick}
      width={400}
      title='Update Cut-off Time'>
      <div className={CutOffTimeClasses}>
        <div className='flex flex-col w-full mt-1'>
          <span className='pb-2 text-N-700'>
            Partner Name
            <span className='text-R-500 ml-[2px]'>*</span>
          </span>
          <SimpleSelect
            disabled={true}
            placeholder='Select Partner'
            value={
              cutoffTimeDetails?.partnerName
                ? {
                    label: cutoffTimeDetails?.partnerName,
                    value: cutoffTimeDetails?.partnerCode,
                  }
                : null
            }
            onChange={(e: any) => {
              //
            }}
            options={partnerList}
            isError={isErrorField?.partnerName}
          />
        </div>
        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>
            Partner Integration <span className='text-R-500 ml-[2px]'>*</span>
          </span>
          <SimpleSelect
            disabled={true}
            placeholder='Select Partner Integration'
            value={
              cutoffTimeDetails?.integrationType
                ? {
                    label: cutoffTimeDetails?.integrationType,
                    value: cutoffTimeDetails?.integrationId,
                  }
                : null
            }
            onChange={(e: any) => {
              setCutoffTimeDetails({
                ...cutoffTimeDetails,
                integrationType: e.label,
                integrationId: e.value,
                integrationURL: e.url,
              })
            }}
            options={[]}
            isError={isErrorField?.integrationType}
          />
        </div>
        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>
            Select Cut-off Time
            <span className='text-R-500 ml-[2px]'>*</span>
          </span>
          <div className='flex gap-x-2 w-full justify-start items-center'>
            <div className='w-1/3'>
              <SimpleSelect
                value={cutoffTimeDetails?.minutes}
                onChange={(e: any) => {
                  setCutoffTimeDetails({
                    ...cutoffTimeDetails,
                    minutes: e,
                  })
                }}
                options={Array.from({ length: 12 }, (_, index) => {
                  const value = (index + 1)?.toString()?.padStart(2, '0')
                  return { label: value, value: value }
                })}
                isError={isErrorField?.minutes}
                placeholder='--'
              />
            </div>
            <div className='text-sm'>:</div>
            <div className='w-1/3'>
              <SimpleSelect
                value={cutoffTimeDetails?.seconds}
                onChange={(e: any) => {
                  setCutoffTimeDetails({
                    ...cutoffTimeDetails,
                    seconds: e,
                  })
                }}
                options={Array?.from({ length: 60 }, (_, index) => {
                  const value = index?.toString()?.padStart(2, '0')
                  return { label: value, value: value }
                })}
                isError={isErrorField?.seconds}
                placeholder='--'
              />
            </div>
            <div className='w-1/3'>
              <SimpleSelect
                value={cutoffTimeDetails?.meridian}
                onChange={(e: any) => {
                  setCutoffTimeDetails({
                    ...cutoffTimeDetails,
                    meridian: e,
                  })
                }}
                options={[
                  { label: 'AM', value: 'AM' },
                  { label: 'PM', value: 'PM' },
                ]}
                isError={isErrorField?.meridian}
              />
            </div>
          </div>
        </div>
        <Input
          labelValue='Before Cut-off Lead Time (Days)'
          isRequired
          value={cutoffTimeDetails.beforeCutoffTime}
          onChange={(e: any) => {
            const re = /^(?:0|[1-9]\d*)$/
            // if value is not blank, then test the regex
            if (e.target.value === '' || re.test(e.target.value)) {
              setCutoffTimeDetails({
                ...cutoffTimeDetails,
                beforeCutoffTime: e.target.value,
              })
            }
          }}
          isError={isErrorField.beforeCutoffTime}
        />
        <Input
          labelValue='After Cut-off Lead Time (Days)'
          isRequired
          value={cutoffTimeDetails.afterCutoffTime}
          onChange={(e: any) => {
            const re = /^(?:0|[1-9]\d*)$/
            // if value is not blank, then test the regex
            if (e.target.value === '' || re.test(e.target.value)) {
              setCutoffTimeDetails({
                ...cutoffTimeDetails,
                afterCutoffTime: e.target.value,
              })
            }
          }}
          isError={isErrorField.afterCutoffTime}
        />
        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>Status</span>
          <ToggleSwitch
            text={'Active'}
            setValue={cutoffTimeDetails?.status}
            onToggleClick={(e: any) => {
              setCutoffTimeDetails({
                ...cutoffTimeDetails,
                status: e.target.checked,
              })
            }}
          />
        </div>
      </div>
      <div className='drawer-footer flex items-center w-full h-[67px] sticky bottom-0 px-5 border-t border-N-200 shadow-sm gap-x-5'>
        <Button
          isLoading={updateCutoffTimeIsLoading}
          disabled={updateCutoffTimeIsLoading}
          isFullWidth
          onClick={() => {
            handleUpdateClick()
          }}>
          Save
        </Button>
        <Button isFullWidth appearance='dull' onClick={onCloseClick}>
          Close
        </Button>
      </div>
    </Drawer>
  )
}
UpdateOrderCutOffTime.defaultProps = {
  className: undefined,
}

export default UpdateOrderCutOffTime
