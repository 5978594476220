import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface getInternalConsignmentExpensesInterface {
  take?: number
  skip?: number
}

export const useGetInternalConsignmentExpenses = () => {
  const mutate = useMutation(
    ({ take, skip }: getInternalConsignmentExpensesInterface) => {
      return get(
        `/InternalReports/ConsignmentExpense?Take=${take}&Skip=${skip}`,
      )
    },
  )

  return mutate
}

export default useGetInternalConsignmentExpenses
