import React, { FC, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import { useGetUserPriviledgesByCategories } from 'framework/api/methods'

import { DataTable } from './UserPrivilegesDataTable'

export interface UserPrivilegesListProps {
  [x: string]: any
}
export interface UserPrivilege {
  privilegeName: string
  description: string
  exactName: string
}
/** This is user privileges */

export const UserPrivilegesList: FC<UserPrivilegesListProps> = ({
  className,
}: UserPrivilegesListProps) => {
  const [rows, setRows] = useState({})

  /** API Call for get user privileges using react-query */
  const {
    mutate: getUserPrivilegesByCategoriesMutate,
    isLoading: getUserPrivilegesByCategoriesIsLoading,
  } = useGetUserPriviledgesByCategories()

  /** Process the get User privileges Table Data */
  async function getUserPrivilegesTableList() {
    getUserPrivilegesByCategoriesMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          const groupedData = successData.categorizedAppPermissions.flatMap(
            (categoryPermission: any) => {
              const categoryName: any = categoryPermission.category.name
              const privileges: UserPrivilege[] =
                categoryPermission.category.userPriviledge.map(
                  (userPrivilege: any) => ({
                    privilegeName: userPrivilege.priviledgeName,
                    privilegeDescription: userPrivilege.description,
                    exactName: userPrivilege.exactName,
                  }),
                )
              const categoryRows = privileges.map(
                (privilege: UserPrivilege, privilegeIndex: number) => ({
                  category: categoryName,
                  ...privilege,
                }),
              )
              // Create a main row for the category and add it followed by individual rows
              return categoryRows
            },
          )
          setRows(groupedData)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  /** notify message */
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  useEffect(() => {
    getUserPrivilegesTableList()
  }, [])

  return (
    <div
      className={CN(
        'flex justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}>
      <div className='flex flex-col h-full w-full rounded-lg bg-white shadow-md px-5 py-7'>
        <div className='flex flex-col w-full h-full bg-white'>
          <h2 className='text-lg font-semibold'>User Privileges</h2>
          <div className='w-full h-full pt-6 pb-5'>
            <DataTable
              itemData={rows}
              getUserPrivilegesByCategoriesIsLoading={
                getUserPrivilegesByCategoriesIsLoading
              }
            />
          </div>

          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
            className='toast-container'
          />
        </div>
      </div>
    </div>
  )
}

UserPrivilegesList.defaultProps = {}

export default UserPrivilegesList
