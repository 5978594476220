import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface getInternalConsignmentOrdersInterface {
  take?: number
  skip?: number
  principle?: any
  courier?: any
  startDate?: string
  endDate?: string
}

export const useGetInternalConsignmentOrders = () => {
  const mutate = useMutation(
    ({
      take,
      skip,
      principle,
      courier,
      startDate,
      endDate,
    }: getInternalConsignmentOrdersInterface) => {
      return get(
        `/InternalReports/ConsignmentOrder?Take=${take}&Skip=${skip}&Principle=${
          principle?.value === 'All' ? '' : principle?.value
        }&Courier=${
          courier?.value === 'All' ? '' : courier?.value
        }&DispatchedFrom=${startDate}&DispatchedTo=${endDate}`,
      )
    },
  )

  return mutate
}

export default useGetInternalConsignmentOrders
