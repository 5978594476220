/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import consignmentExpenseIcon from 'assets/images/reportIcons/consignmentExpense.svg'
import consignmentReportIcon from 'assets/images/reportIcons/consignmentReport.svg'
import courierExpenditureReportIcon from 'assets/images/reportIcons/courierExpenditureReport.svg'
import dailyManifestationReportIcon from 'assets/images/reportIcons/dailyManifestationReport.svg'
import hourlyReceivingOrdersIcon from 'assets/images/reportIcons/hourlyReceivingOrders.svg'
import manifestReportsArchiveIcon from 'assets/images/reportIcons/manifestReportsArchive.svg'
import {
  consignmentExpense,
  consignmentOrders,
  courierExpenditure,
  dailyManifestation,
  hourlyReceivingOrders,
  manifestReportsArchive,
} from 'static-data/reports'

import 'react-tabs/style/react-tabs.css'
import './ReportsTabs.scss'

export interface ReportTabViewProps {
  [x: string]: any
}

export const ReportTabView: FC<ReportTabViewProps> = ({
  tabIndex,
  isTableView,
  setIsTableView,
}: ReportTabViewProps) => {
  const { userDetails }: any = useSelector<any>((state) => state.user)

  const permissionForInternalReportConsignmentExpenseAccess =
    userDetails?.userPermissions?.includes(
      'InternalReportConsignmentExpenseAccess',
    )

  const permissionForInternalReportConsignmentOrderAccess =
    userDetails?.userPermissions?.includes(
      'InternalReportConsignmentOrderAccess',
    )

  const permissionForInternalReportHourlyOrderAccess =
    userDetails?.userPermissions?.includes('InternalReportHourlyOrderAccess')

  const permissionForStarshipITReportManifestArchiveAccess =
    userDetails?.userPermissions?.includes(
      'StarshipITReportManifestArchiveAccess',
    )

  const permissionForStarshipITReportConsignmentOrderAccess =
    userDetails?.userPermissions?.includes(
      'StarshipITReportConsignmentOrderAccess',
    )

  const permissionForStarshipITReportCourierExpenditureAccess =
    userDetails?.userPermissions?.includes(
      'StarshipITReportCourierExpenditureAccess',
    )

  const permissionForStarshipITReportDailyManifestationOrderAccess =
    userDetails?.userPermissions?.includes(
      'StarshipITReportDailyManifestationOrderAccess',
    )

  const InternalReportList = [
    {
      id: 1,
      name: consignmentOrders,
      iconSrc: consignmentReportIcon,
      tab: 'Internal',
      isShown: permissionForInternalReportConsignmentOrderAccess,
    },
    {
      id: 2,
      name: hourlyReceivingOrders,
      iconSrc: hourlyReceivingOrdersIcon,
      tab: 'Internal',
      isShown: permissionForInternalReportHourlyOrderAccess,
    },
    {
      id: 3,
      name: consignmentExpense,
      iconSrc: consignmentExpenseIcon,
      tab: 'Internal',
      isShown: permissionForInternalReportConsignmentExpenseAccess,
    },
  ]

  const StarshipITReportList = [
    {
      id: 1,
      name: consignmentOrders,
      iconSrc: consignmentReportIcon,
      tab: 'Starshipit',
      isShown: permissionForStarshipITReportConsignmentOrderAccess,
    },
    {
      id: 2,
      name: courierExpenditure,
      iconSrc: courierExpenditureReportIcon,
      tab: 'Starshipit',
      isShown: permissionForStarshipITReportCourierExpenditureAccess,
    },
    {
      id: 3,
      name: dailyManifestation,
      iconSrc: dailyManifestationReportIcon,
      tab: 'Starshipit',
      isShown: permissionForStarshipITReportDailyManifestationOrderAccess,
    },
    {
      id: 4,
      name: manifestReportsArchive,
      iconSrc: manifestReportsArchiveIcon,
      tab: 'Starshipit',
      isShown: permissionForStarshipITReportManifestArchiveAccess,
    },
  ]

  return (
    <div className='flex report-section px-10 py-8'>
      <Tabs defaultIndex={tabIndex}>
        <TabList>
          <Tab>Internal Reports</Tab>
          <Tab>Starshipit Reports</Tab>
        </TabList>

        <TabPanel>
          {permissionForInternalReportConsignmentOrderAccess ||
          permissionForInternalReportConsignmentExpenseAccess ||
          permissionForInternalReportHourlyOrderAccess ? (
            <div className='w-full pt-8 border-t border-t-N-200 grid grid-cols-3 gap-x-5 gap-y-8'>
              {InternalReportList?.map((item: any) => {
                if (item?.isShown === false) {
                  return null
                } else {
                  return (
                    <div
                      className='flex px-4 py-5 border border-N-200 rounded-lg items-center hover:bg-N-50 group cursor-pointer'
                      key={item?.id}
                      onClick={() => {
                        setIsTableView({
                          ...isTableView,
                          active: true,
                          report: item?.name,
                          tab: item?.tab,
                        })
                      }}>
                      <div className='bg-N-50 w-12 h-12 flex justify-center items-center rounded-full mr-3 group-hover:bg-white'>
                        <img src={item?.iconSrc} alt='icon' />
                      </div>
                      <div className='text-lg font-semibold text-N-800'>
                        {item?.name}
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          ) : (
            <div className='flex justify-center items-center flex-col w-full pt-[20vh] border-t border-t-N-200'>
              <div className='text-N-700 font-medium text-2xl'>
                No Internal Reports Found
              </div>

              <div className='text-N-500 mt-5'>
                You do not have permission to access these Reports
              </div>
            </div>
          )}
        </TabPanel>

        <TabPanel>
          {permissionForStarshipITReportConsignmentOrderAccess ||
          permissionForStarshipITReportCourierExpenditureAccess ||
          permissionForStarshipITReportDailyManifestationOrderAccess ||
          permissionForStarshipITReportManifestArchiveAccess ? (
            <div className='w-full pt-8 border-t border-t-N-200 grid grid-cols-3 gap-x-5 gap-y-8'>
              {StarshipITReportList?.map((item: any) => {
                if (item?.isShown === false) {
                  return null
                } else {
                  return (
                    <div
                      className='flex px-4 py-5 border border-N-200 rounded-lg items-center hover:bg-N-50 group cursor-pointer'
                      key={item?.id}
                      onClick={() => {
                        setIsTableView({
                          ...isTableView,
                          active: true,
                          report: item?.name,
                          tab: item?.tab,
                        })
                      }}>
                      <div className='bg-N-50 w-12 h-12 flex justify-center items-center rounded-full mr-3 group-hover:bg-white'>
                        <img src={item?.iconSrc} alt='icon' />
                      </div>
                      <div className='flex flex-col'>
                        <span className='text-lg font-semibold text-N-800'>
                          {item?.name}
                        </span>
                        <span className='text-xs text-N-600 mt-1'>
                          {item?.tab}
                        </span>
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          ) : (
            <div className='flex justify-center items-center flex-col w-full pt-[20vh] border-t border-t-N-200'>
              <div className='text-N-700 font-medium text-2xl'>
                No Starshipit Reports Found
              </div>

              <div className='text-N-500 mt-5'>
                You do not have permission to access these Reports
              </div>
            </div>
          )}
        </TabPanel>
      </Tabs>
    </div>
  )
}

ReportTabView.defaultProps = {}

export default ReportTabView
